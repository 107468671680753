import React, { useState, useEffect } from 'react';
import { FaPlus, FaPencilAlt, FaRegTrashAlt, FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Popconfirm, message, Input, Tag } from 'antd';
import { useHistory } from 'react-router-dom';

import { formatCNPJ, formatCPF } from '@brazilian-utils/brazilian-utils';

import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { Table, TableActions, TableHeader } from '~/components/Table';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { normalizeString } from '~/Utils';

export default function Provider() {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/providers');
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/clientes/novo');
  };

  const handleEdit = async (id) => {
    history.push(`/clientes/${id}/edicao`);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`providers/${id}`);
      message.success(t('messages:successDeleteProviders'));
      fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      const results = recordData.filter(
        (item) =>
          (item.company_name && item.company_name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.company_name &&
            normalizeString(item.company_name).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.contact && item.contact.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.contact && normalizeString(item.contact.name).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.ssn && item.ssn.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.ssn && normalizeString(item.ssn).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.ssn &&
            normalizeString(item.ssn.replace(/[^0-9,]*/g, ''))
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.price && Number(item.price).toFixed(2).replace('.', ',').indexOf(searchTerm.toLowerCase()) !== -1)
      );
      setTableData(results);
    } else {
      setTableData(recordData);
    }
  }, [recordData, searchTerm]);

  useEffect(() => {
    fetchRecordData();
  }, []);

  const tableColumns = [
    {
      title: t('fields:type'),
      key: 'type',
      render: (text, record) => (
        <Tag style={{ textTransform: 'uppercase' }} color={record.type === 'provider' ? 'orange' : 'green'}>
          {record.type === 'provider' ? 'Fornecedor' : 'Cliente'}
        </Tag>
      ),
    },
    {
      title: t('screens:providers.data.company_name_name'),
      key: 'company_name',
      render: (text, record) => (record.company_name ? record.company_name : record.contact && record.contact.name),
    },
    {
      title: t('fields:ssn'),
      key: 'ssn',
      render: (text, record) => (
        <span style={{ textTransform: 'uppercase' }}>
          {record.business ? formatCNPJ(record.ssn, { pad: true }) : formatCPF(record.ssn, { pad: true })}
        </span>
      ),
    },
    {
      title: t('screens:providers.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@provider/edit">
            <Button style={{ margin: 'auto' }} title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@provider/edit">
            <Can permission="@provider/view">
              <Button style={{ margin: 'auto' }} title={t('messages:view')} onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@provider/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              onConfirm={() => {
                handleDelete(record.id);
              }}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <Button title={t('messages:delete')}>
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:providers.title')}>
        <Can permission="@provider/edit">
          <Button color="primary" loading={loading} onClick={handleNew}>
            <FaPlus />
            {t('screens:providers.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table loading={loading} columns={tableColumns} dataSource={tableData} />
      </Box>
    </DefaultLayout>
  );
}
