/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Spin, Tag, message } from 'antd';
import { FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';

import errorHandler from '~/Utils/errorHandler';

import { Table, TableHeader, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { ModalFooter } from '~/components/Modal';
import { getColorByAbstractStatus, decrypt } from '~/Utils';
import api from '~/services/api';
import Pagination from '~/components/Pagination';

import AbstractForm from './form';

export default function Abstract({ visible, onClose, congressID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const handleExport = async () => {
    setLoading(true);
    try {
      const { congress } = recordData[0];

      if (!congress) {
        return;
      }
      const { slug } = congress;
      await api
        .get(`/congresses/exportResumesBy/${congress.id}`, {
          method: 'GET',
          responseType: 'blob',
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', `resumos_${slug}_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleExportTxt = async () => {
    setLoading(true);
    try {
      const { congress } = recordData[0];

      if (!congress) {
        return;
      }
      const { slug } = congress;
      await api
        .get(`/congresses/exportResumesTxtBy/${congress.id}`, {
          method: 'GET',
          responseType: 'blob',
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', `resumos_${slug}_${new Date().toLocaleDateString()}.txt`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      if (congressID) {
        const { data } = await api.get(`/congresses/getResumes/${congressID}`, {
          params: {
            page,
            search,
          },
        });
        setRecordData(data.data);
        setMeta(data.meta);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);

    try {
      await api.delete(`/congresses/delete-resumes/${id}`);
      message.success('Resumo deletado com sucesso!');
      fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (visible && !showForm) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, showForm, page, search]);

  const handleView = resume => {
    setShowForm(true);
    setSelectedRecord(resume);
  };

  const tableColumns = [
    {
      title: t('screens:abstracts.data.title'),
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: t('fields:person_id'),
      dataIndex: 'person_id',
      key: 'person_id',
      render: (record, text) => text.person && text.person.name,
      ellipsis: true,
    },
    {
      title: t('fields:evaluator_id'),
      dataIndex: 'evaluator_id',
      key: 'evaluator_id',
      render: (record, text) => text.evaluator && text.evaluator.name,
      ellipsis: true,
    },
    {
      title: t('screens:abstracts.data.subarea'),
      dataIndex: 'subArea.name',
      key: 'subArea.name',
      render: (text, record) => record.subArea && record.subArea.name,
      ellipsis: true,
    },
    {
      title: t('screens:abstracts.data.updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: text => new Date(text).toLocaleString(),
    },
    {
      title: t('screens:abstracts.data.words'),
      dataIndex: 'word',
      key: 'word',
      ellipsis: true,
      render: (text, record) => {
        let words;
        if (record.word_1) {
          words = `${record.word_1}; `;
        }
        if (record.word_2) {
          words += `${record.word_2}; `;
        }
        if (record.word_3) {
          words += `${record.word_3}; `;
        }
        if (record.word_4) {
          words += `${record.word_4}; `;
        }
        if (record.word_5) {
          words += `${record.word_5}; `;
        }
        return words;
      },
    },
    {
      title: t('screens:abstracts.data.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <Tag color={getColorByAbstractStatus(record.status)}>{record.status}</Tag>,
    },
    {
      title: t('screens:abstracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        // console.log('Congress/Abstract/index => acitons', record),
        <div style={{ display: 'flex' }}>
          <TableActions>
            {permissions.includes('@superAdmin') ? (
              <Button style={{ margin: 'auto' }} size="small" title="Editar" onClick={() => handleView(record)}>
                <FaPencilAlt />
              </Button>
            ) : (
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleView(record)}>
                <FaEye />
              </Button>
            )}
          </TableActions>
          <TableActions>
            <Button size="small" title="Excluir" onClick={() => handleDelete(record.id)}>
              <FaRegTrashAlt />
            </Button>
          </TableActions>
        </div>
      ),
    },
  ];

  return (
    <Modal
      title={t('screens:abstracts.title')}
      onCancel={onClose}
      visible={visible}
      loading={loading}
      centered
      width="1250px"
      footer={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          <Button
            disabled={recordData && recordData.length === 0}
            loading={loading}
            onClick={handleExport}
            style={{ position: 'absolute' }}
          >
            {t('screens:subscriptions.export')}
          </Button>
          <Button
            disabled={recordData && recordData.length === 0}
            loading={loading}
            onClick={handleExportTxt}
            style={{ position: 'absolute', left: '120px' }}
          >
            Exportar TXT
          </Button>
          <ModalFooter loading={loading} type="info" onCancel={onClose} />
        </>
      }
    >
      <Spin spinning={loading}>
        <Box>
          <TableHeader>
            <Input.Search onSearch={value => setSearch(value)} className="search-field" />
          </TableHeader>
          <Table rowKey="id" loading={loading} dataSource={recordData} columns={tableColumns} pagination={false} />
          <Pagination
            onChange={value => {
              setPage(value);
            }}
            meta={meta}
          />
        </Box>

        <AbstractForm
          visible={showForm}
          resume={selectedRecord}
          onClose={() => {
            setShowForm(false);
            setSelectedRecord(null);
          }}
        />
      </Spin>
    </Modal>
  );
}
