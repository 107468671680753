import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import Menu from '~/pages/_partials/Menu';
import Header from '~/pages/_partials/Header';
import Footer from '~/pages/_partials/Footer';
import Top from '~/pages/_partials/Top';
import EnviromentHeader from '~/pages/_partials/EnviromentHeader';

import { Container } from './styles';

export default function FullLayout({ children }) {
  const { Content } = Layout;
  const enviroment = process.env.REACT_APP_ENVIROMENT;

  return (
    <Container className="layout">
      {enviroment !== 'production' && <EnviromentHeader />}
      <Top />
      <Header>
        <Menu />
      </Header>
      <Content>{children}</Content>
      <Footer />
    </Container>
  );
}

FullLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
