import axios from 'axios';
import i18next from 'i18next';
import { forceLogout } from '~/Utils';

const getBaseURLByEnviroment = enviroment => {
  let url;
  switch (enviroment) {
    case 'production':
      url = 'https://api.admin.sbfv.org.br/v1/api';
      break;
    case 'local':
      url = 'http://localhost:8000/v1/api';
      break;
    default:
      url = 'https://api.admin-stg.sbfv.org.br/v1/api';
      break;
  }

  return url;
};

const api = axios.create({
  baseURL: getBaseURLByEnviroment(process.env.REACT_APP_ENVIROMENT),
});

const isAuthTry = (url, method) => url.indexOf('api/auth') && method === 'post';

api.interceptors.response.use(
  response => response,
  error => {
    const { config } = error;
    const status = error.response ? error.response.status : null;
    const { url, method } = config;

    if (status === 401 && !isAuthTry(url, method)) {
      // return new Promise();
      forceLogout(i18next.t('messages:expiredSession'));
    }
    return Promise.reject(error);
  }
);

export default api;
