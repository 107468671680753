import React, { useEffect, useState } from 'react';
import { Col, Modal, Spin, message } from 'antd';
import PropTypes from 'prop-types';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { Formik } from 'formik';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import * as Yup from 'yup';
import { Form, Input, Select, Switch } from 'formik-antd';
import { ModalFooter } from '~/components/Modal';
import { useTranslation } from 'react-i18next';

export default function SubscriptionForm({ showForm, person, onClose }) {
  const [loading, setLoading] = useState(false);
  const [congressesData, setCongressesData] = useState([]);
  const [congressSelected, setCongressSelected] = useState([]);
  const { t } = useTranslation();

  const fetchCongresses = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/congresses/getAllNotExpired');

      setCongressesData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      await api.post('/subscriptions', values);
      message.success('Inscrição realizada com sucesso!');
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const getCongressById = id => congressesData.find(congress => congress.id === id);

  useEffect(() => {
    if (showForm) {
      fetchCongresses();
    }
  }, [showForm]);

  const initialValues = {
    congress_id: '',
    person_id: person.id,
    course_id: null,
    badge_name: '',
    badge_institution: '',
    foreign: false,
    tax_exempt: false,
    translator: false,
    by_admin: true,
  };

  const validation = Yup.object().shape({
    congress_id: Yup.number().required(),
    person_id: Yup.number().required(),
    badge_name: Yup.string().required('Nome para o crachá campo obrigatório'),
    badge_institution: Yup.string().nullable(),
    foreign: Yup.boolean().required(),
    translator: Yup.boolean().nullable(),
    tax_exempt: Yup.boolean().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validation}
      enableReinitialize
    >
      {({ errors, submitForm, resetForm, isSubmitting }) => (
        <Modal
          title={`${t('screens:people.subscribing')}: ${person.name}`}
          onCancel={() => onClose()}
          afterClose={resetForm}
          style={{ maxWidth: '1100px' }}
          visible={showForm}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={() => onClose()} />
          }
        >
          <Spin spinning={loading}>
            <Form style={{ padding: '0 40px 0 40px' }}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl field="person_id" label="Nome" error={errors.person_id}>
                    <Input disabled value={person.name} />
                  </FormControl>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl error={errors.congress_id} label="Congresso" field="congress_id">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={congressSelected?.id ?? 'Selecione um congresso'}
                      onSelect={value => {
                        const selectedCongress = getCongressById(value);
                        setCongressSelected(selectedCongress);
                      }}
                      name="congress_id"
                    >
                      {congressesData?.map(congress => (
                        <Select.Option key={congress.id} value={congress.id}>
                          {congress.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl label="Nome para o crachá" error={errors.badge_name} field="badge_name">
                    <Input name="badge_name" autoComplete="dontshow" />
                  </FormControl>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormControl
                    label="Nome da instituição para o crachá"
                    error={errors.badge_institution}
                    field="badge_institution"
                  >
                    <Input name="badge_institution" autoComplete="dontshow" />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <FormControl field="foreign" error={errors.foreign} label="Estrangeiro?">
                    <Switch name="foreign" />
                  </FormControl>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <FormControl field="tax_exempt" error={errors.tax_exempt} label="Isento?">
                    <Switch name="tax_exempt" />
                  </FormControl>
                </Col>
                {congressSelected?.translated ? (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormControl field="translator" error={errors.translator} label="Tradutor?">
                      <Switch name="translator" active={false} />
                    </FormControl>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

SubscriptionForm.propTypes = {
  showForm: PropTypes.bool.isRequired,
  person: PropTypes.instanceOf.isRequired,
  onClose: PropTypes.func.isRequired,
};
