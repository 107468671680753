import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import api from '~/services/api';

const AssociatedChart = () => {
  const [chartData, setChartData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await api
      .get('/dashboard/data')
      .then((response) => {

        // use destructuring to get the data.people from the response
        const { data } = response;
        const { people } = data;

        const students = {
          label: `Estudantes (${people.students})`,
          data: people.students,
        };
        const professionals = {
          label: `Profissionais (${people.professionals})`,
          data: people.professionals,
        };
        const posGraduates = {
          label: `Pós-Graduados (${people.posGraduates})`,
          data: people.posGraduates,
        };
        const posDoctorates = {
          label: `Pós-Doutorados (${people.posDoctorates})`,
          data: people.posDoctorates,
        };
        const formattedData = {
          labels: [students.label, professionals.label, posGraduates.label, posDoctorates.label],
          datasets: [
            {
              label: 'Distribuição de Pessoas',
              data: [
                students.data,
                professionals.data,
                posGraduates.data,
                posDoctorates.data,
              ],
              backgroundColor: [
                'rgba(255, 99, 132, 0.7)',
                'rgba(192, 162, 235, 0.7)',
                'rgba(255, 206, 86, 0.7)',
                'rgba(75, 192, 192, 0.7)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(192, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };
        setChartData(formattedData);
      })
      .catch((error) => {
        console.error('Error loading the data', error);
      });
  }, []);

  return <div>{chartData ? <Pie data={chartData} /> : <p>Loading...</p>}</div>;
};

export default AssociatedChart;
