import styled, { createGlobalStyle } from 'styled-components';
import { Upload } from 'antd';

export const Global = createGlobalStyle`
  .address-grid .ant-col{
    padding: unset !important;
  }
  .profile-info-bool .form-control{
    align-items: center;
  }
  .ant-table-wrapper{
  width: 100%;
}
`;

export const Container = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: ${(props) => props.size} !important;
    height: ${(props) => props.size} !important;
    max-width: ${(props) => props.size} !important;
    max-height: ${(props) => props.size} !important;
  }
`;

export const DivTitle = styled.h1`
  color: ${(props) => props.theme.color1};
`;
