/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { decrypt } from '~/Utils/index';

import api from '~/services/api';

export default function EvaluatorsForm({ visible, onClose, congressID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [evaluators, setEvaluators] = useState([]);
  const [loading, setLoading] = useState(false);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      await api.put(`/congresses/insertEvaluators/${congressID}`, {
        evaluators: values.evaluators,
      });
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/congresses/${congressID}`);
      const data = JSON.parse(response.data);
      setRecordData({
        ...data,
        evaluators: data.evaluators.map((item) => item.id),
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchEvaluators = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/people/evaluators/avaliable');
      setEvaluators(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchEvaluators();
      await fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const evaluatorsSchema = Yup.object().shape({
    evaluators: Yup.array().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={evaluatorsSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.description}` : null}
          onCancel={onClose}
          style={{ maxWidth: '1100px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@congress/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.evaluators}
                      field="evaluators"
                      label={t('screens:congresses.evaluators')}
                      required
                    >
                      <Select
                        name="evaluators"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                        allowClear
                        showArrow
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                      >
                        {evaluators &&
                          evaluators.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
