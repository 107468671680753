import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Remember from '~/pages/Password/Remember';
import Reset from '~/pages/Password/Reset';

import Login from '../pages/Login';

export default function SignRoutes() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Login} />

      <Route exact path="/senha/link" component={Remember} />
      <Route exact path="/senha/reset/:token" component={Reset} />
    </BrowserRouter>
  );
}
