/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Input, Form, DatePicker, Select, Radio } from 'formik-antd';
import MaskedInput from 'antd-mask-input';
import { useTranslation } from 'react-i18next';
import { Spin, message, Col, Upload, Radio as RadioAntd, Tabs, Checkbox, Divider, Space } from 'antd';
import { FaUpload } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import * as cep from 'cep-promise';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import Row from '~/components/Row';
import Thumb from '~/components/Thumb';
import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import errorHandler from '~/Utils/errorHandler';
import { decrypt, validateCpf, getBase64 } from '~/Utils/index';
import { DivTitle } from './styles';
import PersonSubscriptions from './personSubscriptions';
import PersonTransactions from './personTransactions';
import PersonMovements from './personMovements';
import './styles.css';

const CheckboxGroup = Checkbox.Group;

const defaultValues = {
  name: '',
  cpf: '',
  rg: '',
  dob: '',
  profession: '',
  email: '',
  nationality: '',
  company: '',
  department: '',
  academic_title: '',
  validity: '',
  verified: false,
  foreign: false,
  document: '',
  graduation_type: '',
  is_student: false,
  is_evaluator: false,
  is_admin: false,
  associated: false,
  active: true,
  specialization_1: '',
  specialization_2: '',
  specialization_3: '',
  address: {
    zipcode: '',
    address: '',
    address_number: '',
    address_complement: '',
    city: '',
    state: '',
    country: '',
    neighborhood: 'não informado',
    phone: '',
    phone_extension: '',
    fax: '',
    fax_extension: '',
    mobile: '',
  },
};

export default function Person({ match }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));
  const { needs, nationalities } =
    JSON.parse(decrypt(localStorage.getItem('@App:needs'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:needs')));
  const graduationTypes = needs.graduation_types;
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [file, setFile] = useState(null);
  const [deleteOldFile, setDeleteOldFile] = useState(false);

  const [congresses, setCongresses] = useState([]);
  const [checkedList, setCheckedList] = useState([]); // defaultCheckedList
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [congressesOptions, setCongressesOptions] = useState([]);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < congressesOptions.length);
    setCheckAll(list.length === congressesOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? congressesOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const getCongressId = (description) => {
    const congress = congresses.find((congress_) => congress_.description === description);
    return congress.id;
  };

  // get all non expired congresses
  // eslint-disable-next-line consistent-return
  const fetchNonExpiredCongresses = async () => {
    try {
      const response = await api.get('/congresses/getAllNotExpired');
      const { data } = response;
      setCongresses(data);
      const congressesDescriptions = data.map((congress) => congress.description);
      setCongressesOptions(congressesDescriptions);
      return congressesDescriptions;
    } catch (error) {
      console.log('error', error);
      errorHandler(error);
    }
  };

  const fetchRecordData = async (congressesData = null) => {
    try {
      // eslint-disable-next-line react/prop-types
      const { id } = match.params;
      if (id) {
        const response = await api.get(`/people/${id}`);
        const data = JSON.parse(response.data);
        setFile(data.receipt);
        document.getElementById('uploadButton').style.cursor = data.receipt !== null ? 'no-drop' : 'pointer';
        setRecordData(data);

        if (congressesData) {
          let congressesDescriptions = Object.keys(data.congresses);

          // remove congresses descriptions that are not in the list of non expired congresses
          congressesDescriptions = congressesDescriptions.filter(
            (description) => congressesData.includes(description)
            // congressesOptions.includes(description)
          );
          setCheckedList(congressesDescriptions);
        }
      } else {
        setRecordData(defaultValues);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const congressesData = await fetchNonExpiredCongresses();
      await fetchRecordData(congressesData);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const setFieldError = (setErrors, msg, field) => {
    if (msg) {
      message.error(msg);
    }
    const errors = {};
    errors[field] = msg;
    setErrors(errors);
  };

  const handleFindAddress = async (setErrors, initialValues, value, values) => {
    setLoading(true);
    try {
      if (!values.foreign) {
        // const address = await cep(value);
        const address = await cep(value, { timeout: 10000 });
        const data = {
          ...values,
          address: {
            ...values.address,
            zipcode: value,
            city: address.city,
            state: address.state,
            neighborhood: address.neighborhood === '' ? '-' : address.neighborhood,
            address: address.street,
            /* address: address.street ( values.address.address.toString() === "" ? address.street : values.address.address.toString() ), */
            country: 'Brasil',
          },
        };
        setRecordData(data);
      } else {
        const data = {
          ...values,
          address: {
            ...values.address,
            zipcode: value,
            city: values.address.city,
            state: values.address.state,
            neighborhood: values.address.neighborhood === '' ? '-' : values.address.neighborhood,
            address: values.address.address,
            /* address: address.street ( values.address.address.toString() === "" ? address.street : values.address.address.toString() ), */
            country: 'Brasil',
          },
        };
        setRecordData(data);
      }
    } catch (error) {
      console.log('CEP não localizado');
      if (
        initialValues.address.address === '' ||
        initialValues.address.address === null ||
        initialValues.address.address === undefined
      ) {
        setErrors({ address: { address: 'Aviso: Endereço não localizado pelo CEP.' } });
      }
    }
    setLoading(false);
  };

  const handleCPF = (cpf, values, setErrors, setValues) => {
    if (!validateCpf(cpf)) {
      setFieldError(setErrors, 'CPF inválido', 'cpf');
      setValues({
        ...values,
        cpf: null,
      });
      return false;
    }

    setFieldError(setErrors, null, 'cpf');
    return true;
  };

  const handleSave = async (values, { setErrors, setValues }) => {
    setLoading(true);
    const validCpf = values.cpf && (await handleCPF(values.cpf.replace(/[^0-9,]*/g, ''), values, setErrors, setValues));
    const selectedCongressesIds = checkedList.map((description) => getCongressId(description));
    const avaliableCongressesIds = congresses.map((congress) => congress.id);
    const CongressesIdsToDelete = avaliableCongressesIds.filter(
      (congressId) => !selectedCongressesIds.includes(congressId)
    );

    values.congressesToAdd = selectedCongressesIds;
    values.congressesToDelete = CongressesIdsToDelete;

    if (validCpf || values.foreign) {
      try {
        if (values.id) {
          if (deleteOldFile === true) {
            await api.delete(`/files/${values.id}/receipt`);
            values.receipt_id = null;
          }
          await api.put(`/people/${values.id}`, values);
          if (file && file.originFileObj) {
            const files = new FormData();
            files.append('file', file.originFileObj);
            await api.post(`/files/${values.id}/receipt`, files);
          }
          message.success(t('messages:successUpdatePeople'));
        } else {
          const { data } = await api.post('/people', values);
          const person = JSON.parse(data);
          if (file && file.originFileObj) {
            const files = new FormData();
            files.append('file', file.originFileObj);
            await api.post(`/files/${person.id}/receipt`, files);
          }
          message.success(t('messages:successPeople'));
        }
        setFile(null);
        setDeleteOldFile(null);
        history.push(`/pessoas`);
      } catch (error) {
        setErrors(errorHandler(error));
      }
    }
    setLoading(false);
  };

  const PhoneInput = (inputName, inputPlaceholder, inputMask) => (
    <Field
      name={inputName}
      render={({ field }) => (
        <MaskedInput
          mask={inputMask}
          {...field}
          id={inputName}
          placeholder={inputPlaceholder}
          disabled={permissions && !permissions.includes('@people/edit')}
        />
      )}
    />
  );

  const handleBack = () => {
    history.push('/pessoas');
  };

  const handleRemoveFile = () => {
    document.getElementById('uploadButton').style.cursor = 'pointer';
    setFile(null);
    setDeleteOldFile(true);
  };

  const beforeUpload = (newFile) => {
    setLoading(true);

    let justOne = true;
    if (file) {
      justOne = false;
      message.error('Você só pode anexar 1 arquivo');
    }

    const isValid =
      newFile.type === 'image/jpeg' ||
      newFile.type === 'image/jpg' ||
      newFile.type === 'image/png' ||
      newFile.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }
    const isLt2M = newFile.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 20MB!');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }

    setLoading(false);
    return isValid && isLt2M && justOne;
  };

  const handleUpload = (event) => {
    setLoading(true);

    if (!event.file.originFileObj) {
      setLoading(false);
      return;
    }

    let extension = '';
    let name = '';
    if (event.file.name.substring(event.file.name.length - 4) === 'jpeg') {
      extension = event.file.name.substring(event.file.name.length - 5);
      name = event.file.name.replaceAll('.', '');
      name = name.substring(0, name.length - 4);
    } else {
      extension = event.file.name.substring(event.file.name.length - 4);
      name = event.file.name.replaceAll('.', '');
      name = name.substring(0, name.length - 3);
    }
    event.file.name = name + extension;

    if (!event.file.id) {
      getBase64(event.file.originFileObj, (image) => {
        event.file.base64Url = image;
      });
    }
    document.getElementById('uploadButton').style.cursor = 'no-drop';
    setTimeout(() => {
      setFile(event.file);
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    fetchScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategory = (isStudent, graduationType) => {
    if (isStudent) return 'student';
    if (!isStudent && graduationType === 'postdoctoral') return 'postdoctoral';
    if (!isStudent && graduationType === null) return 'professional';
    return 'professional';
  };

  const personSchema = Yup.object().shape({
    name: Yup.string().required().min(5),
    email: Yup.string().email().required(),
    academic_title: Yup.string().nullable(),
    specialization_1: Yup.string().nullable(),
    specialization_2: Yup.string().nullable(),
    specialization_3: Yup.string().nullable(),
    department: Yup.string().nullable(),
    company: Yup.string().nullable(),
    graduation_type: Yup.string()
      .nullable()
      .when('is_student', {
        is: true,
        then: Yup.string()
          .matches(/(graduate|doctor|master|postdoctoral)/, 'Insira um tipo válido!', { excludeEmptyString: true })
          .required(),
      }),
    nationality: Yup.string().required(),
    dob: Yup.date().required(),
    cpf: Yup.string().nullable().when('foreign', {
      is: false,
      then: Yup.string().required(),
    }),
    document: Yup.string()
      .nullable()
      .when('foreign', {
        is: true,
        then: Yup.string().required().min(4),
      }),
    active: Yup.boolean().required(),
    rg: Yup.string().nullable().min(8),
    validity: Yup.date().nullable().when('associated', {
      is: true,
      then: Yup.date().required(),
    }),
    verified: Yup.boolean().nullable(),
    foreign: Yup.boolean().required(),
    is_student: Yup.boolean().required(),
    is_evaluator: Yup.boolean().required(),
    is_admin: Yup.boolean().required(),
    associated: Yup.boolean().required(),
    address: Yup.object().shape({
      zipcode: Yup.string().required(),
      address: Yup.string().required(),
      address_number: Yup.string().required(),
      address_complement: Yup.string().nullable(),
      city: Yup.string().required(),
      state: Yup.string().required().max(2),
      country: Yup.string().required(),
      neighborhood: Yup.string().required(),
      phone: Yup.string()
        .nullable()
        .test('phone', 'Insira um formato de telefone válido.', (value) =>
          !value ? true : value?.replace(/\D/g, '')?.length === 10
        ),
      phone_extension: Yup.string().nullable(),
      fax: Yup.string().nullable(),
      fax_extension: Yup.string().nullable(),
      mobile: Yup.string()
        .when('phone', {
          is: (value) => !value,
          then: Yup.string().required(),
        })
        .test('mobile', 'Insira um formato de celular válido.', (value) => value?.replace(/\D/g, '')?.length === 11),
    }),
  });

  return (
    <DefaultLayout>
      <PageTitle
        title={t('screens:people.title')}
        subtitle={recordData.name ? `editando - ${recordData.name}` : null}
      />
      <Box>
        {' '}
        <Tabs
          type="card"
          defaultActiveKey="1"
          size="large"
          style={{
            marginBottom: 32,
          }}
        >
          <Tabs.TabPane tab="Dados" key="1" style={{ padding: 16 }}>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={recordData}
              enableReinitialize
              onSubmit={handleSave}
              validationSchema={personSchema}
            >
              {({ errors, values, setErrors, setValues, setFieldValue, initialValues }) => (
                <Spin spinning={loading}>
                  <Form>
                    <Input type="hidden" name="id" />
                    {/* {
                  // show all formik errors
                  Object.keys(errors).length > 0 ? console.log(errors) : console.log('no errors')
                } */}
                    <DivTitle style={{ paddingBottom: 24 }}>{t('screens:people.data.data')}</DivTitle>
                    <Row>
                      <Col xs={8}>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.name}
                          field="name"
                          label={t('screens:people.data.name')}
                          required
                        >
                          <Input name="name" disabled={permissions && !permissions.includes('@people/edit')} />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.email}
                          field="email"
                          label={t('screens:people.data.email')}
                          required
                        >
                          <Input
                            name="email"
                            type="email"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.profession}
                          field="profession"
                          label={t('screens:people.data.profession')}
                        >
                          <Input name="profession" disabled={permissions && !permissions.includes('@people/edit')} />
                        </FormControl>
                        <FormControl
                          error={errors.foreign}
                          cols={{ xs: 24 }}
                          field="foreign"
                          label={t('screens:people.data.foreign')}
                          required
                        >
                          <Radio.Group
                            name="foreign"
                            disabled={permissions && !permissions.includes('@people/edit')}
                            buttonStyle="solid"
                          >
                            <Radio.Button value>{t('messages:yes')}</Radio.Button>
                            <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                          </Radio.Group>
                        </FormControl>
                        {!values.foreign ? (
                          <>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={errors.rg}
                              field="rg"
                              label={t('screens:people.data.rg')}
                            >
                              <Field
                                name="rg"
                                style={{ height: '32px', border: '0.1px solid #dddddd' }}
                                // render={({ field }) => (
                                //   <MaskedInput mask="11.111.111-1" {...field} id="rg" placeholder="__.___.___-_" />
                                // )}
                              />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={errors.cpf}
                              field="cpf"
                              label={t('screens:people.data.cpf')}
                              required
                            >
                              <Field
                                name="cpf"
                                render={({ field }) => (
                                  <MaskedInput
                                    mask="111.111.111-11"
                                    {...field}
                                    id="cpf"
                                    onBlur={(e) => {
                                      const cpf = e.target.value.replace(/[^0-9,]*/g, '');
                                      handleCPF(cpf, values, setErrors, setValues);
                                    }}
                                    placeholder="___.___.___-__"
                                  />
                                )}
                              />
                            </FormControl>
                          </>
                        ) : (
                          <>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={errors.document}
                              field="document"
                              label={t('screens:people.data.document')}
                              required
                            >
                              <Input name="document" disabled={permissions && !permissions.includes('@people/edit')} />
                            </FormControl>
                          </>
                        )}
                      </Col>
                      <Col xs={8}>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.dob}
                          field="dob"
                          label={t('screens:people.data.dob')}
                          required
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            name="dob"
                            placeholder={t('messages:select')}
                            locale={locale}
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.nationality}
                          field="nationality"
                          label={t('fields:nationality')}
                        >
                          <Select name="nationality" allowClear showSearch optionFilterProp="children">
                            {nationalities &&
                              nationalities.map((item) => (
                                <Select.Option key={item.nome} value={item.nome}>
                                  {item.nome}
                                </Select.Option>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.company}
                          field="company"
                          label={t('screens:people.data.company')}
                        >
                          <Input name="company" disabled={permissions && !permissions.includes('@people/edit')} />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.department}
                          field="department"
                          label={t('screens:people.data.department')}
                        >
                          <Input name="department" disabled={permissions && !permissions.includes('@people/edit')} />
                        </FormControl>
                      </Col>
                      <Col xs={8}>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.academic_title}
                          field="academic_title"
                          label={t('screens:people.data.academic_title')}
                        >
                          <Input
                            name="academic_title"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.specialization_1}
                          field="specialization_1"
                          label={t('screens:people.data.specialization_1')}
                        >
                          <Input
                            name="specialization_1"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.specialization_2}
                          field="specialization_2"
                          label={t('screens:people.data.specialization_2')}
                        >
                          <Input
                            name="specialization_2"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.specialization_3}
                          field="specialization_3"
                          label={t('screens:people.data.specialization_3')}
                        >
                          <Input
                            name="specialization_3"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8}>
                        <DivTitle>{t('screens:people.data.address')}</DivTitle>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.zipcode}
                          field="address.zipcode"
                          label={values.foreign ? 'Zip Code*' : t('screens:people.data.zipcode')}
                          required
                        >
                          <Field
                            name="address.zipcode"
                            render={({ field }) => (
                              <MaskedInput
                                mask="11.111-111"
                                {...field}
                                disabled={permissions && !permissions.includes('@people/edit')}
                                placeholder="__.___-__"
                                autoComplete="dontshow"
                                onBlur={(e) => {
                                  const zipcode = e.target.value.replace(/[^0-9,]*/g, '');
                                  if (zipcode.length === 8) {
                                    handleFindAddress(setErrors, initialValues, zipcode, values);
                                  }
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.address}
                          field="address.address"
                          label={t('screens:people.data.address')}
                          required
                        >
                          <Input
                            name="address.address"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.address_number}
                          field="address.address_number"
                          label={t('screens:people.data.address_number')}
                          required
                        >
                          <Input
                            name="address.address_number"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.address_complement}
                          field="address.address_complement"
                          label={t('screens:people.data.address_complement')}
                        >
                          <Input
                            name="address.address_complement"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.neighborhood}
                          field="address.neighborhood"
                          label={t('screens:people.data.address_neighborhood')}
                          required
                        >
                          <Input
                            name="address.neighborhood"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.city}
                          field="address.city"
                          label={t('screens:people.data.city')}
                          required
                        >
                          <Input name="address.city" disabled={permissions && !permissions.includes('@people/edit')} />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.state}
                          field="address.state"
                          label={t('screens:people.data.state')}
                          required
                        >
                          <Input
                            name="address.state"
                            disabled={permissions && !permissions.includes('@people/edit')}
                            maxLength={2}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.country}
                          field="address.country"
                          label={t('screens:people.data.country')}
                          required
                        >
                          <Input
                            name="address.country"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={8}>
                        <DivTitle>{t('screens:people.data.contact')}</DivTitle>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.phone}
                          field="address.phone"
                          label={t('screens:people.data.phone')}
                        >
                          <Field
                            name="address.phone"
                            style={{ height: '32px', border: '0.1px solid #dddddd' }}
                            // render={({ field }) => (
                            //   <MaskedInput
                            //     mask={inputMask}
                            //     {...field}
                            //     id={inputName}
                            //     placeholder={inputPlaceholder}
                            //     disabled={permissions && !permissions.includes('@people/edit')}
                            //   />
                            // )}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.phone_extension}
                          field="address.phone_extension"
                          label={t('screens:people.data.phone_extension')}
                        >
                          <Input
                            name="address.phone_extension"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.fax}
                          field="address.fax"
                          label={t('screens:people.data.fax')}
                        >
                          <Input name="address.fax" disabled={permissions && !permissions.includes('@people/edit')} />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.fax_extension}
                          field="address.fax_extension"
                          label={t('screens:people.data.fax_extension')}
                        >
                          <Input
                            name="address.fax_extension"
                            disabled={permissions && !permissions.includes('@people/edit')}
                          />
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.address && errors.address.mobile}
                          field="address.mobile"
                          label={t('screens:people.data.mobile')}
                          required
                        >
                          {PhoneInput('address.mobile', '(__) _____-____', '(11) 11111-1111')}
                        </FormControl>
                      </Col>
                      <Col xs={8}>
                        <DivTitle>{t('screens:people.data.features')}</DivTitle>
                        {!recordData.id && (
                          <FormControl
                            error={errors.verified}
                            cols={{ xs: 24 }}
                            field="verified"
                            label={t('screens:people.data.verified')}
                          >
                            <Radio.Group name="verified" disabled buttonStyle="solid">
                              <Radio.Button value>{t('messages:yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                            </Radio.Group>
                          </FormControl>
                        )}
                        <FormControl
                          error={errors.active}
                          cols={{ xs: 24 }}
                          field="active"
                          label={t('screens:people.data.active')}
                        >
                          <Radio.Group
                            name="active"
                            disabled={permissions && !permissions.includes('@people/edit')}
                            buttonStyle="solid"
                          >
                            <Radio.Button value>{t('messages:yes')}</Radio.Button>
                            <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                          </Radio.Group>
                        </FormControl>
                        <FormControl
                          error={errors.associated}
                          cols={{ xs: 24 }}
                          field="associated"
                          label={t('screens:people.data.associated')}
                        >
                          <Radio.Group
                            name="associated"
                            disabled={permissions && !permissions.includes('@people/edit')}
                            buttonStyle="solid"
                          >
                            <Radio.Button value>{t('messages:yes')}</Radio.Button>
                            <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                          </Radio.Group>
                        </FormControl>
                        {values.associated && (
                          <FormControl
                            cols={{ xs: 24 }}
                            error={errors.validity}
                            field="validity"
                            label={t('screens:people.data.validity')}
                            required
                          >
                            <DatePicker
                              format="DD/MM/YYYY"
                              name="validity"
                              placeholder={t('messages:select')}
                              locale={locale}
                              disabled={permissions && !permissions.includes('@people/edit')}
                            />
                          </FormControl>
                        )}
                        <FormControl error={errors.is_student} cols={{ xs: 24 }} field="is_student" label="Categoria">
                          <RadioAntd.Group
                            name="category"
                            disabled={permissions && !permissions.includes('@people/edit')}
                            buttonStyle="solid"
                            value={handleCategory(values.is_student, values.graduation_type)}
                            onChange={(e) => {
                              if (e.target.value === 'student') {
                                setFieldValue('is_student', true);
                                setFieldValue('graduation_type', 'graduate');
                              } else {
                                setFieldValue('is_student', false);
                              }
                              if (e.target.value === 'postdoctoral') {
                                setFieldValue('graduation_type', 'postdoctoral');
                              }
                              if (e.target.value === 'professional') {
                                setFieldValue('graduation_type', null);
                              }
                            }}
                          >
                            <RadioAntd.Button value="student">Estudante</RadioAntd.Button>
                            <RadioAntd.Button value="professional">Profissional</RadioAntd.Button>
                            <RadioAntd.Button value="postdoctoral">Pós-Doc</RadioAntd.Button>
                          </RadioAntd.Group>
                          <Input name="is_student" type="hidden" />
                        </FormControl>
                        {values.is_student && (
                          <FormControl
                            cols={{ xs: 24 }}
                            error={errors.graduation_type}
                            field="graduation_type"
                            label={t('screens:people.data.graduation_type')}
                            required
                          >
                            <Select
                              name="graduation_type"
                              disabled={permissions && !permissions.includes('@people/edit')}
                              allowClear
                              autoComplete="dontshow"
                              showSearch
                              optionFilterProp="children"
                            >
                              {graduationTypes &&
                                graduationTypes.map((item) => {
                                  if (item.id !== 'postdoctoral')
                                    return (
                                      <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                      </Select.Option>
                                    );
                                  return null;
                                })}
                            </Select>
                          </FormControl>
                        )}
                        <FormControl
                          error={errors.is_evaluator}
                          cols={{ xs: 24 }}
                          field="is_evaluator"
                          label={t('screens:people.data.is_evaluator')}
                        >
                          <Radio.Group
                            name="is_evaluator"
                            disabled={permissions && !permissions.includes('@people/edit')}
                            buttonStyle="solid"
                          >
                            <Radio.Button value>{t('messages:yes')}</Radio.Button>
                            <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                          </Radio.Group>
                        </FormControl>
                        {values.is_evaluator && (
                          <>
                            <Space direction="vertical">
                              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                Adicionar Avaliador em todos os congressos vigentes
                              </Checkbox>
                              <Divider />
                              <CheckboxGroup options={congressesOptions} value={checkedList} onChange={onChange} />
                            </Space>
                            <FormControl
                              style={{ marginTop: 16 }}
                              error={errors.is_admin}
                              cols={{ xs: 24 }}
                              field="is_admin"
                              label={t('screens:people.data.is_admin')}
                            >
                              <Radio.Group
                                name="is_admin"
                                disabled={permissions && !permissions.includes('@people/edit')}
                                buttonStyle="solid"
                              >
                                <Radio.Button value>{t('messages:yes')}</Radio.Button>
                                <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                              </Radio.Group>
                            </FormControl>
                          </>
                        )}
                        <Row>
                          <Can permission="@people/edit">
                            <Upload
                              disabled={file}
                              showUploadList={false}
                              beforeUpload={beforeUpload}
                              onChange={handleUpload}
                            >
                              <Button style={{ marginLeft: '1rem' }} id="uploadButton" color="default">
                                Anexar arquivo
                                <FaUpload />
                              </Button>
                            </Upload>
                          </Can>
                        </Row>
                        <Row>
                          <Thumb
                            file={file}
                            fileURL={recordData.receipt_url}
                            onDelete={() => {
                              handleRemoveFile();
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <FormActions>
                        <Can permission="@people/edit">
                          <Button type="submit" color="primary">
                            Salvar
                          </Button>
                        </Can>
                        <Button onClick={handleBack}>Cancelar</Button>
                      </FormActions>
                    </Row>
                  </Form>
                </Spin>
              )}
            </Formik>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Inscrições Ativas" key="2" style={{ padding: 16 }}>
            <DivTitle style={{ paddingBottom: 24 }}>Inscrições Ativas</DivTitle>
            <PersonSubscriptions
              subscriptions={recordData.subscriptions}
              person={recordData}
              fetchRecordData={fetchRecordData}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Transações" key="3" style={{ padding: 16 }}>
            <DivTitle style={{ marginBottom: -55 }}>Transações</DivTitle>
            <PersonTransactions personId={recordData.id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Movimentos" key="4" style={{ padding: 16 }}>
            <DivTitle style={{ marginBottom: -15 }}>Movimentos</DivTitle>
            <PersonMovements personId={recordData.id} personName={recordData.name} />
          </Tabs.TabPane>
        </Tabs>
      </Box>
    </DefaultLayout>
  );
}
