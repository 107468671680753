import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaLockOpen, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { Input, Popconfirm, message } from 'antd';

import errorHandler from '~/Utils/errorHandler';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import Box from '~/components/Box';
import { TableHeader, Table, TableActions } from '~/components/Table';
import DefaultLayout from '~/pages/_layouts/full';
import api from '~/services/api';
import { normalizeString } from '~/Utils';
import RoleForm from './form';
import PermissionForm from './permissions';

export default function Roles() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [showPermissionForm, setShowPermissionForm] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles');
      setRecordList(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNewRole = () => {
    setShowRoleForm(true);
  };

  const handleEdit = (id) => {
    setSelectedRecord(id);
    setShowRoleForm(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/roles/${id}`);
      fetchRecords();
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handlePermissions = async (role) => {
    setSelectedRecord(role);
    setShowPermissionForm(true);
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      const results = recordList.filter(
        (item) =>
          (item.name && item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.name && normalizeString(item.name).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.slug && item.slug.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
      );
      setTableData(results);
    } else {
      setTableData(recordList);
    }
  }, [searchTerm, recordList]);

  useEffect(() => {
    if (!showRoleForm && !showPermissionForm) {
      fetchRecords();
      setSelectedRecord(null);
    }
  }, [showRoleForm, showPermissionForm]);

  const tableColumns = [
    {
      title: t('screens:roles.data.name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('screens:roles.data.slug'),
      key: 'slug',
      dataIndex: 'slug',
    },
    {
      key: 'actions',
      width: '140px',
      render: (text, record) => (
        <TableActions>
          <Button onClick={() => handleEdit(record.id)} title={t('messages:edit')}>
            <FaPencilAlt />
          </Button>
          <Popconfirm
            title={t('messages:confirmDelete')}
            // icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            okText={t('messages:delete')}
            cancelText={t('messages:no')}
            onConfirm={() => handleDelete(record.id)}
          >
            <Button title={t('messages:edit')}>
              <FaRegTrashAlt />
            </Button>
          </Popconfirm>
          <Button onClick={() => handlePermissions(record)}>
            <FaLockOpen />
          </Button>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:roles.title')}>
        <Button color="primary" onClick={handleNewRole}>
          <FaPlus />
          {t('screens:roles.btnNew')}
        </Button>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />
      </Box>
      <RoleForm visible={showRoleForm} roleID={selectedRecord} onClose={() => setShowRoleForm(false)} />
      <PermissionForm
        visible={showPermissionForm}
        roleID={selectedRecord && selectedRecord.id}
        rolePermissions={selectedRecord ? selectedRecord.permissions : null}
        onClose={() => setShowPermissionForm(false)}
      />
    </DefaultLayout>
  );
}
