import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 14rem;
  max-height: 14rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
`;

export const Image = styled.div`
  button {
    border: 1px solid #000;
  }

  img {
    width: 9rem;
    height: 9rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.5rem;
  button {
    height: 1.5rem;
    border: 0;
    p {
      margin: auto;
    }
  }
`;
