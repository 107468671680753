import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import api from '~/services/api';

const MovementsChartMonth = () => {
  const [chartData, setChartData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/movements/getMonthlyMovements');
        const rawData = response.data;

        // Agrupar os dados por ano e mês
        const groupedData = rawData.reduce((acc, [year, month, income, expense]) => {
          const key = `${month}/${year}`;
          if (!acc[key]) {
            acc[key] = { income: 0, expense: 0 };
          }
          acc[key].income += income;
          acc[key].expense -= expense;
          return acc;
        }, {});

        // Preparar os dados para o gráfico
        const labels = Object.keys(groupedData);
        const incomes = labels.map(label => groupedData[label].income);
        const expenses = labels.map(label => groupedData[label].expense);

        const formattedData = {
          labels,
          datasets: [
            {
              label: 'Entradas',
              data: incomes,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              // Definir o grupo 'bar' para 1
              barThickness: 'flex',
            },
            {
              label: 'Saídas',
              data: expenses.map(value => value), // Mantendo saídas positivas para um agrupado positivo
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              // Definir o grupo 'bar' para 1
              barThickness: 'flex',
            },
          ],
        };

        setChartData(formattedData);
      } catch (error) {
        console.error('Error loading the data', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {chartData ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            scales: {
              x: {
                stacked: false, // Impede o empilhamento e permite agrupamento
              },
              y: {
                stacked: false,
              },
            },
            plugins: {
              legend: {
                display: true,
              },
            },
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MovementsChartMonth;


// import React, { useState, useEffect } from 'react';
// import { Bar } from 'react-chartjs-2';
// import api from '~/services/api';

// const MovementsChartMonth = () => {
//   const [chartData, setChartData] = useState();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await api.get('/movements/getMonthlyMovements');
//         const rawData = response.data;

//         // Agrupar os dados por ano e mês
//         const groupedData = rawData.reduce((acc, [year, month, income, expense]) => {
//           const key = `${month}/${year}`;
//           if (!acc[key]) {
//             acc[key] = { income: 0, expense: 0 };
//           }
//           acc[key].income += income;
//           acc[key].expense += expense;
//           return acc;
//         }, {});

//         // Preparar os dados para o gráfico
//         const labels = Object.keys(groupedData);
//         const incomes = labels.map(label => groupedData[label].income);
//         const expenses = labels.map(label => groupedData[label].expense);

//         const formattedData = {
//           labels,
//           datasets: [
//             {
//               label: 'Entradas',
//               data: incomes,
//               backgroundColor: 'rgba(75, 192, 192, 0.5)',
//               borderColor: 'rgba(75, 192, 192, 1)',
//             },
//             {
//               label: 'Saídas',
//               data: expenses.map(value => -value), // Convertendo saídas em valores negativos para o gráfico
//               backgroundColor: 'rgba(255, 99, 132, 0.5)',
//               borderColor: 'rgba(255, 99, 132, 1)',
//             },
//           ],
//         };

//         setChartData(formattedData);
//       } catch (error) {
//         console.error('Error loading the data', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return <div>{chartData ? <Bar data={chartData} /> : <p>Loading...</p>}</div>;
// };

// export default MovementsChartMonth;
