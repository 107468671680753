import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import api from '~/services/api';

const OrdersChartMonth = () => {
  const [chartData, setChartData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await api
      .get('/orders/getMonthlyOrders')
      .then((response) => {
        const { data } = response;

        const formattedData = {
          labels: data?.original?.map((item) => `${item.month}/${item.year}`),
          datasets: [
            {
              label: 'Total',
              data: data.original.map((item) => item.total_orders),
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              borderColor: 'rgba(53, 162, 235, 1)',
            },
            {
              label: 'Pagas',
              data: data.original.map((item) => item.paid_charges),
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              borderColor: 'rgba(75, 192, 192, 1)',
            },
            {
              label: 'Pendentes',
              data: data.original.map((item) => item.pending_charges),
              backgroundColor: 'rgba(255, 205, 86, 0.5)',
              borderColor: 'rgba(255, 205, 86, 1)',
            },
            {
              label: 'Canceladas',
              data: data.original.map((item) => item.canceled_charges),
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
            },
          ],
        };
        setChartData(formattedData);
      })
      .catch((error) => {
        console.error('Error loading the data', error);
      });
  }, []);

  return <div>{chartData ? <Bar data={chartData} /> : <p>Loading...</p>}</div>;
};

export default OrdersChartMonth;
