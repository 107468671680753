/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { FaPlus, FaPencilAlt, FaEye, FaGift, FaFileExcel } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { message, Input, Switch, Tag, Select, Space } from 'antd'; // eslint-disable-line
import { useHistory } from 'react-router-dom';

import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { Table, TableActions, TableHeader } from '~/components/Table';
import Pagination from '~/components/Pagination';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { getColorByPersonStatus, dynamicFormatPhone } from '~/Utils'; // eslint-disable-line
import GiftForm from './gift';

export default function Person() {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('all');
  const [type, setType] = useState('all');
  const [recordData, setRecordData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [meta, setMeta] = useState({});

  const handleExport = async () => {
    setLoading(true);
    try {
      await api
        .get('/people/export/all', {
          method: 'get',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `pessoas_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response.status === 400) {
        message.warning('Nenhum registro encontrado.');
      }
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await api.get('/people?page=1', {
        params: {
          search,
          status,
          type,
        },
      });
      const { data } = response;
      setRecordData(data.data);
      setMeta(data.meta);
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async (page) => {
    setLoading(true);
    try {
      const response = await api.get(`/people?page=${page}`, {
        params: {
          search,
          status,
          type,
        },
      });
      const { data } = response;

      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/pessoas/novo');
  };

  const handleEdit = async (id) => {
    history.push(`/pessoas/${id}/edicao`);
  };

  // const handleDelete = async (id) => {
  //   setLoading(true);
  //   try {
  //     await api.delete(`people/${id}`);
  //     message.success(t('messages:successDeletepeople'));
  //     handleSearch();
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  //   setLoading(false);
  // };

  const handleChangeActive = async (record) => {
    setLoading(true);
    try {
      await api.put(`/people/${record.id}`, record);
      handleSearch();
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, status, type]);

  const tableColumns = [
    {
      title: t('screens:people.data.name'),
      key: 'name',
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      title: t('screens:people.data.email'),
      key: 'email',
      render: (text, record) => <span>{record.email}</span>,
    },
    {
      title: t('screens:people.data.phone'),
      key: 'address.phone',
      render: (text, record) => (
        <span>{record.address && record.address.phone && dynamicFormatPhone(record.address.phone)}</span>
      ),
    },
    {
      title: `${t('screens:people.data.status')} ${t('screens:people.data.associated')}`,
      key: 'status',
      render: (text, record) => (
        // console.log('record.status', record.status)
        <Tag style={{ textTransform: 'uppercase' }} color={getColorByPersonStatus(record.status)}>
          {record.status}
        </Tag>
      ),
    },
    {
      title: t('screens:people.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@people/edit">
            <Button style={{ margin: 'auto' }} title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@people/edit">
            <Can permission="@people/view">
              <Button style={{ margin: 'auto' }} title={t('messages:view')} onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@people/delete">
            <Switch
              style={{ marginTop: '10px' }}
              name="active"
              size="small"
              checked={record.active}
              onChange={(e) => {
                record.active = e;
                delete record.files;
                handleChangeActive(record);
              }}
            />
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:people.title')}>
        <Can permission="@people/edit">
          <Button
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
            color="primary"
            loading={loading}
            onClick={() => handleExport()}
          >
            <FaFileExcel />
            {t('screens:people.btnExport')}
          </Button>
          <Button
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
            color="primary"
            loading={loading}
            onClick={() => setVisible(true)}
          >
            <FaGift />
            {t('screens:gifts.btnNew')}
          </Button>
          <Button style={{ display: 'inline-block' }} color="primary" loading={loading} onClick={handleNew}>
            <FaPlus />
            {t('screens:people.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Space direction="horizontal" size={24}>
            <div>
              <label htmlFor="type-select">Tipo  </label>
              <Select
                id="type-select"
                style={{ textTransform: 'uppercase', width: '120px' }}
                defaultValue="all"
                onChange={(value) => {
                  setType(value);
                }}
              >
                <Select.Option style={{ textTransform: 'uppercase' }} value="all">
                  {t('screens:people.data.all')}
                </Select.Option>
                <Select.Option style={{ textTransform: 'uppercase' }} value="student">
                  {t('screens:people.data.student')}
                </Select.Option>
                <Select.Option style={{ textTransform: 'uppercase' }} value="graduate">
                  {t('screens:people.data.graduate')}
                </Select.Option>
                <Select.Option style={{ textTransform: 'uppercase' }} value="postdoc">
                  {t('screens:people.data.postdoc')}
                </Select.Option>
                <Select.Option style={{ textTransform: 'uppercase' }} value="professional">
                  {t('screens:people.data.professional')}
                </Select.Option>
              </Select>
            </div>
            <div>
              <label htmlFor="status-select">Status  </label>
              <Select
                id="status-select"
                style={{
                  textTransform: 'uppercase',
                  width: '120px',
                  color:
                    status === 'all'
                      ? 'black'
                      : status === 'active'
                      ? 'green'
                      : status === 'inactive'
                      ? 'red'
                      : 'orange',
                }}
                defaultValue="all"
                onChange={(value) => setStatus(value)}
              >
                <Select.Option style={{ textTransform: 'uppercase' }} value="all">
                  {t('screens:people.data.all')}
                </Select.Option>
                <Select.Option style={{ color: 'green', textTransform: 'uppercase' }} value="active">
                  {t('screens:people.data.active')}
                </Select.Option>
                <Select.Option style={{ color: 'red', textTransform: 'uppercase' }} value="inactive">
                  {t('screens:people.data.inactive')}
                </Select.Option>
                <Select.Option style={{ color: 'orange', textTransform: 'uppercase' }} value="pending">
                  {t('screens:people.data.pending')}
                </Select.Option>
              </Select>
            </div>

            <Input.Search onSearch={(value) => setSearch(value)} className="search-field" />
          </Space>
        </TableHeader>
        <Table pagination={false} rowKey="id" loading={loading} dataSource={recordData} columns={tableColumns} />
        <Pagination
          onChange={(value) => {
            handleChangePage(value);
          }}
          meta={meta}
        />
      </Box>
      <GiftForm visible={visible} onClose={() => setVisible(false)} />
    </DefaultLayout>
  );
}
