/* eslint-disable react/prop-types */
import React from 'react';
import { Menu as AntMenu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';

import Button from '~/components/Button';

import { decrypt } from '~/Utils/index';

import { MenuGlobalStyle, Nav } from './styles';

export default function Menu() {
  const { t } = useTranslation();

  const { permissions: permissionList, roles: roleList } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const checkAuth = (permission, role) => {
    let auth = false;
    if (roleList && !roleList.includes('@superAdmin')) {
      if (role) {
        auth = roleList && roleList.includes(role);
      } else if (permission) {
        auth = permissionList && permissionList.includes(permission);
      } else {
        return false;
      }
    } else {
      return true;
    }
    return auth;
  };

  const MenuItem = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) ? <AntMenu.Item {...props}>{children}</AntMenu.Item> : null;

  const SubMenu = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) && <AntMenu.SubMenu {...props}>{children}</AntMenu.SubMenu>;

  const renderSubMenuTitle = (label) => (
    <>
      <span>{label}</span>
      <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" />
    </>
  );

  const renderMenu = (item) => {
    const { type } = item;
    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    }
    if (type === 'sub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={renderSubMenuTitle(item.label)}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    if (type === 'rightSub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={item.label}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    return (
      <MenuItem key={item.key} permission={item.permission}>
        {item.click ? (
          <Button onClick={item.click}>
            {item.icon && item.icon}
            {item.label}
          </Button>
        ) : (
          <Link to={item.url}>
            {item.icon && item.icon}
            {item.label}
          </Link>
        )}
      </MenuItem>
    );
  };

  const menuItems = [
    {
      type: 'item',
      key: 'dash',
      permission: '@dashboard/view',
      label: t('menus:dash'),
      url: '/',
    },
    {
      type: 'sub',
      key: 'registrations',
      permission: '@registrations/view',
      label: t('menus:registrations'),
      children: [
        {
          type: 'item',
          key: 'reg.users',
          permission: '@user/view',
          label: t('menus:reg_users'),
          url: '/usuarios',
        },
        {
          type: 'item',
          key: 'set.roles',
          permission: '@superAdmin',
          label: t('menus:set_roles'),
          url: '/roles',
        },
        {
          type: 'item',
          key: 'set.managements',
          permission: '@user/view',
          label: t('menus:set_managements'),
          url: '/gerencias',
        },
        {
          type: 'divider',
          key: 'set_div_1',
        },
        {
          type: 'item',
          key: 'reg.people',
          permission: '@people/view',
          label: t('menus:reg_people'),
          url: '/pessoas',
        },
        {
          type: 'item',
          key: 'reg.customers',
          permission: '@customer/view',
          label: t('menus:reg_customers'),
          url: '/clientes',
        },
        {
          type: 'item',
          key: 'reg.costCenters',
          permission: '@costCenter/view',
          label: t('menus:reg_costCenters'),
          url: '/centros-de-custo',
        },
        {
          type: 'item',
          key: 'reg.costs',
          permission: '@costCenter/view',
          label: t('menus:reg_costs'),
          url: '/custos',
        },
        {
          type: 'item',
          key: 'reg.bankAccount',
          permission: '@bankAccount/view',
          label: t('menus:reg_bankAccounts'),
          url: '/contas-bancarias',
        },
        {
          type: 'item',
          key: 'reg.annuities',
          permission: '@annuity/view',
          label: t('menus:reg_annuities'),
          url: '/anuidades',
        },
      ],
    },
    {
      type: 'sub',
      key: 'congresses',
      permission: '@congress/view',
      label: t('menus:congresses'),
      children: [
        {
          type: 'item',
          key: 'reg.congresses',
          permission: '@congress/view',
          label: t('menus:congresses'),
          url: '/congressos',
        },
        {
          type: 'item',
          key: 'reg.courses',
          permission: '@course/view',
          label: t('menus:courses'),
          url: '/cursos',
        },
        {
          type: 'item',
          key: 'reg.paymentLots',
          permission: '@paymentLot/view',
          label: t('menus:paymentLots'),
          url: '/lotes-de-pagamento',
        },
        {
          type: 'item',
          key: 'reg.speeches',
          permission: '@speech/view',
          label: t('menus:speeches'),
          url: '/palestras',
        },
        {
          type: 'item',
          key: 'reg.areas',
          permission: '@area/view',
          label: t('menus:areas'),
          url: '/areas',
        },
        {
          type: 'item',
          key: 'reg.subareas',
          permission: '@subArea/view',
          label: t('menus:subAreas'),
          url: '/subareas',
        },
      ],
    },
    {
      type: 'item',
      key: 'movements',
      permission: '@movement/view',
      label: t('menus:movements'),
      url: '/movimentos',
    },
    {
      type: 'item',
      key: 'transactions',
      permission: '@movement/view',
      label: t('menus:transactions'),
      url: '/transacoes',
    },
    {
      type: 'item',
      key: 'reviews',
      permission: '@report/view',
      label: t('menus:reviews'),
      url: '/relatorios',
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      <Nav>
        <AntMenu mode="horizontal" defaultSelectedKeys={['cadastros']}>
          {menuItems.map((item) => renderMenu(item))}
        </AntMenu>
      </Nav>
    </>
  );
}
