/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { AiOutlineLogout, AiOutlineUser, AiOutlineQuestionCircle } from 'react-icons/ai';
import { Menu as AntMenu } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';

import Logo from '~/components/Logo';

import UserInfo from '~/pages/_partials/UserInfo';
import Profile from '~/pages/Profile/form';
import { useAuth } from '~/contexts/auth';

import { decrypt } from '~/Utils/index';
import { useLocation } from 'react-router-dom';
import TransactionTutorial from '~/pages/Help/transactionTutorial';
import { Container } from './styles';

export default function Top() {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);
  const [showTransactionTutorial, setShowTransactionTutorial] = useState(false);
  const { name, id } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));
  const context = useAuth();
  const location = useLocation();
  const isTransacoesPage = location.pathname === '/transacoes';

  function handleSignOut() {
    context.Logout();
  }

  function handleHelp() {
    setShowTransactionTutorial(true);
    // TransactionTutorial(true);
  }

  function handleProfile() {
    setShowProfile(true);
  }

  const renderSubMenuTitle = (label) => (
    <>
      <span>{label}</span>
      <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" />
    </>
  );

  return (
    <Container>
      <Logo height="47px" width="386px" margin="0 0 0 40px" />
      <UserInfo>
        <AntMenu mode="horizontal" defaultSelectedKeys={['perfil']}>
          <AntMenu.SubMenu key="perfil" title={renderSubMenuTitle(name)}>
            <AntMenu.Item key="profile" onClick={handleProfile}>
              <AiOutlineUser /> {t('menus:user_profile')}
            </AntMenu.Item>
            {isTransacoesPage && (
              <AntMenu.Item key="help" onClick={handleHelp}>
                <AiOutlineQuestionCircle /> {t('menus:help')}
              </AntMenu.Item>
            )}
            <AntMenu.Item key="logout" onClick={handleSignOut}>
              <AiOutlineLogout /> {t('menus:user_logout')}
            </AntMenu.Item>
          </AntMenu.SubMenu>
        </AntMenu>
        <Profile userID={id} visible={showProfile} onClose={() => setShowProfile(false)} />
        <TransactionTutorial
          visible={showTransactionTutorial}
          onClose={() => setShowTransactionTutorial(false)}
        />
      </UserInfo>
    </Container>
  );
}
