import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import api from '~/services/api';

const RegisteredChart = () => {
  const [chartData, setChartData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await api
      .get('/dashboard/data')
      .then((response) => {
        const { data } = response;
        const { newPeople } = data;

        if (!newPeople) {
          return;
        }

        const dataChart = Object.keys(newPeople).map((item) => ({
            month: item,
            students: newPeople[item].students,
            professionals: newPeople[item].professionals,
            postGraduates: newPeople[item].postGraduates,
            postDoctorates: newPeople[item].postDoctorates,
            total: Object.values(newPeople[item]).reduce((acc, curr) => acc + curr, 0),
          }));

        const formattedData = {
          labels: dataChart.map((item) => item.month),
          datasets: [
            {
              label: 'Estudantes',
              data: dataChart.map((item) => item.students),
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
            },
            {
              label: 'Profissionais',
              data: dataChart.map((item) => item.professionals),
              backgroundColor: 'rgba(192, 162, 235, 0.5)',
              borderColor: 'rgba(192, 162, 235, 1)',
            },
            {
              label: 'Pós-Graduados',
              data: dataChart.map((item) => item.postGraduates),
              backgroundColor: 'rgba(255, 205, 86, 0.5)',
              borderColor: 'rgba(255, 205, 86, 1)',
            },
            {
              label: 'Pós-Doutorados',
              data: dataChart.map((item) => item.postDoctorates),
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              borderColor: 'rgba(75, 192, 192, 1)',
            },
            {
              label: 'Total',
              data: dataChart.map((item) => item.total),
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              borderColor: 'rgba(53, 162, 235, 1)',
            },
          ],
        };

        setChartData(formattedData);
      })
      .catch((error) => {
        console.error('Error loading the data', error);
      });
  }, []);

  return <div>{chartData ? <Line data={chartData} /> : <p>Loading...</p>}</div>;
};

export default RegisteredChart;
