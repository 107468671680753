/* eslint-disable */
import React, { useState } from 'react';
import { Table, TableActions, TableHeader } from '~/components/Table';
import { FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Popconfirm, message } from 'antd';
import errorHandler from '~/Utils/errorHandler';
import Button from '~/components/Button';
import Can from '~/components/Can';
import api from '~/services/api';
import SubscriptionForm from './formSubscription';

export default function PersonSubscriptions({ subscriptions, fetchRecordData, person }) {
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);

  if (!subscriptions) return <p>Carregando...</p>;

  subscriptions = subscriptions
    .sort((a, b) => new Date(a.congress.initial_date) - new Date(b.congress.initial_date))
    .filter(subscription => new Date(subscription.congress.final_date) > new Date());

  const { t } = useTranslation();

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/subscriptions/deleteSubscriptionAndItems/${id}`);
      fetchRecordData(); // refetch all data
      message.success(t('messages:successDeleteSubscription'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tipo',
      key: 'type',
      render: record => {
        // if record.course_id is null, then it is a congress
        if (record.course_id === null) {
          // return bold for congress
          return <span style={{ fontWeight: 'bold' }}>Congresso</span>;
        } else {
          return <span>Curso</span>;
        }
      },
    },
    {
      title: 'Nome',
      key: 'description',
      render: record => {
        if (record.course_id === null) {
          return <span style={{ fontWeight: 'bold' }}>{record.congress.description}</span>;
        } else {
          return (
            <>
              <span>{record.course.description}</span>
              <br />
              <span style={{ fontSize: '0.8em' }}>({record.congress.description})</span>
            </>
          );
        }
      },
    },
    {
      title: 'Data de Início',
      key: 'initial_date',
      render: record => {
        if (record.course_id === null) {
          return (
            <span style={{ fontWeight: 'bold' }}>{new Date(record.congress.initial_date).toLocaleDateString()}</span>
          );
        } else {
          return new Date(record.course.date_time).toLocaleDateString();
        }
      },
    },
    {
      title: t('screens:congresses.data.actions'),
      key: 'actions',
      align: 'center',
      render: record => (
        <TableActions>
          <Can permission="@congress/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <>
      <TableHeader>
        <Button
          style={{ display: 'inline-block' }}
          color="primary"
          loading={loading}
          onClick={() => setFormVisible(true)}
        >
          <FaPlus />
          {t('screens:people.subscribe_people')}
        </Button>
      </TableHeader>
      <Table rowKey="name" dataSource={subscriptions} columns={tableColumns} />
      <SubscriptionForm
        showForm={formVisible}
        person={person}
        onClose={() => {
          setFormVisible(false);
          fetchRecordData();
        }}
      />
    </>
  );
}
