/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import api from '~/services/api';
import DefautLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import errorHandler from '~/Utils/errorHandler';
import { formatPrice } from '~/Utils/format';
import Can from '~/components/Can';
import { decrypt } from '~/Utils';
import OrdersChartMonth from '~/components/Charts/OrdersChartMonth';
import AssociatedChart from '~/components/Charts/AssociatedChart';
import RegisteredChart from '~/components/Charts/RegisteredChart';
import NewRegisteredChart from '~/components/Charts/NewRegisteredChart';
import MovementsChartMonth from '~/components/Charts/MovementsChartMonth';
import CongressList from '~/components/Charts/CongressList';
import ConselhoFiscalPage from './conselhoFiscalPage';
import { Box, Title, Balance } from './styles';

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [congresses, setCongresses] = useState([]);
  const { roles } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/dashboard/data');
      setCongresses(data.congressesMetrics);
      setRecordData(data.balance);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefautLayout>
      <PageTitle title="Dashboard" />
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 24 },
          { xs: 8, sm: 16, md: 24, lg: 24 },
        ]}
      >
        {roles && roles.includes('@conselhofiscal') && (
          <ConselhoFiscalPage />)}

        {roles && !roles.includes('@organizer') && (
          <Can permission="@dashboard/view">
            {/* REGISTERED PIE */}
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Box>
                <Title>Pessoas registradas</Title>
                <p style={{ textAlign: 'center', marginBottom: 20, fontSize: 12, color: '#93c051' }}>Por status</p>
                <RegisteredChart />
              </Box>
              <div style={{ marginTop: 15 }} />
            </Col>
            {/* ASSOCIATES PIE */}
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Box>
                <Title>Associados</Title>
                <p style={{ textAlign: 'center', marginBottom: 20, fontSize: 12, color: '#93c051' }}>(Regulares)</p>
                <AssociatedChart />
              </Box>
            </Col>
            {/* TRANSACTIONS */}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Box>
                <Title>Transações</Title>
                <p style={{ textAlign: 'center', marginBottom: 20, fontSize: 12, color: '#93c051' }}>Últimos 6 meses</p>
                <OrdersChartMonth />
              </Box>
            </Col>
            {/* NEW PEOPLE */}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Box>
                <Title>Novos associados</Title>
                <p style={{ textAlign: 'center', marginBottom: 20, fontSize: 12, color: '#93c051' }}>
                  Últimos 12 meses
                </p>
                <NewRegisteredChart />
              </Box>
            </Col>
            {/* MOVEMENTS */}
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Box>
                <Title>Movimentos</Title>
                <p style={{ textAlign: 'center', marginBottom: 20, fontSize: 12, color: '#93c051' }}>Últimos 6 meses</p>
                <MovementsChartMonth />
              </Box>
            </Col>
            {/* TOTAL */}
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Box>
                <Title>Saldo Financeiro Total</Title>
                <Balance>{loading ? <Spin spinning /> : formatPrice(recordData)}</Balance>
              </Box>
            </Col>
          </Can>
        )}
      </Row>
      <Can role="@organizer">
        <Can permission="@dashboard/view">
          <CongressList congresses={congresses} />
        </Can>
      </Can>
      <Can permission="@dashboard/view">
        <CongressList congresses={congresses} />
      </Can>
    </DefautLayout>
  );
}
