/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, DatePicker, Input } from 'formik-antd';
import { Modal, message, Spin, Col, Upload } from 'antd';
import { FaCamera, FaRegTrashAlt, FaUpload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import { decrypt } from '~/Utils/index';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import moment from 'moment';
import { ImagePreview, UploadStyle, Container } from './styles';

const initialValues = {
  name: '',
  description: 'Presidente da SBFV',
  signature_id: null,
  signature_url: null,
  initial_date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
  final_date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
};

export default function ManagementForm({ visible, onClose, selectedRecord }) {
  const { t } = useTranslation();
  const recordData = { ...initialValues, ...selectedRecord };
  const [imageUrl, setImageUrl] = useState(recordData.signature_url);
  const [file, setFile] = useState(null);
  const [changedFile, setChangedFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    values.initial_date = moment(values.initial_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    // values.final_date = moment(values.final_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    try {
      if (values.id) {
        await api.put(`/management/${values.id}`, values);
        if (changedFile === true) {
          if (imageUrl !== null) {
            const files = new FormData();
            files.append('file', file.originFileObj);
            await api.post(`management/${recordData.id}/signature`, files);
            message.success(t('messages:success'));
            onClose();
          } else {
            await api.delete(`management/${recordData.id}/signature`);
            message.error('É necessário adicionar uma assinatura!');
          }
        } else {
          message.success(t('messages:success'));
          onClose();
        }
      } else if (file && file.originFileObj) {
        const dataManagement = await Promise.all([api.post('/management', values)]);
        const { id: managementId } = dataManagement[0].data;
        const files = new FormData();
        files.append('file', file.originFileObj);
        await api.post(`management/${managementId}/signature`, files);
        message.success(t('messages:success'));
        onClose();
      } else {
        message.error('É necessário adicionar uma assinatura!');
      }
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (signature) => {
    const isJpgOrPng =
      signature.type === 'image/jpeg' || signature.type === 'image/jpg' || signature.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = signature.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ser menor que 10MB!');
    }
    // verify if image has width under 500px and height under 150px
    const img = new Image();
    img.src = window.URL.createObjectURL(signature);
    img.onload = () => {
      const { width, height } = img;
      if (width < 400 || height < 150) {
        message.info('O tamanho recomendado da imagem é de no máximo 400px de largura e 150px de altura.');
      }
    };
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    setLoading(true);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.originFileObj) {
      getBase64(info.file.originFileObj, (image) => {
        setImageUrl(image);
        setFile(info.file);
      });
    }
    setLoading(false);
  };

  const uploadButton = (
    <>
      <FaCamera size={24} />
      <div className="ant-upload-text">{t('messages:add')}</div>
    </>
  );

  const handleUpload = async () => {
    setChangedFile(true);
  };

  const uploadConfig = {
    name: 'file',
    action: handleUpload,
    listType: 'picture-card',
    showUploadList: false,
    beforeUpload,
    onChange: handleChange,
  };

  const managementSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    initial_date: Yup.date().required().min('1900-01-01'),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      onClose={() => {
        onClose();
        setChangedFile(false);
      }}
      onCancel={() => {
        onClose();
        setChangedFile(false);
      }}
      validationSchema={managementSchema}
    >
      {({ errors, isSubmitting, values, submitForm, resetForm, setFieldValue }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.name}` : t('screens:managements.title')}
          onCancel={onClose}
          style={{ maxWidth: '400px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@user/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  <FormControl
                    cols={{ xs: 24 }}
                    required
                    field="signature"
                    label={t('screens:managements.data.signature')}
                    style={{ alignItems: 'center' }}
                  >
                    <>
                      <UploadStyle />
                      {imageUrl || values.signature_url ? (
                        <ImagePreview
                          className="img-preview"
                          sizeWidth="350px"
                          sizeMaxWidth="350px"
                          sizeHeight="100px"
                          sizeMaxHeight="100px"
                          background={imageUrl || values.signature_url}
                        >
                          <div className="img-preview-wrapper">
                            <div
                              aria-hidden="true"
                              className="img-preview-delete"
                              onClick={() => {
                                setImageUrl(null);
                                setFile(null);
                                setFieldValue('signature_url', null);
                                setChangedFile(true);
                              }}
                            >
                              <FaRegTrashAlt size={24} />
                              {t('messages:delete')}
                            </div>
                            <div className="img-preview-file" />
                          </div>
                        </ImagePreview>
                      ) : (
                        <Container {...uploadConfig} size="350px">
                          <Spin spinning={loading}>{imageUrl || uploadButton}</Spin>
                        </Container>
                      )}
                    </>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <FormControl error={errors.name} field="name" label={t('screens:managements.data.name')} required>
                    <Input name="name" disabled={permissions && !permissions.includes('@user/edit')} />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl
                    error={errors.description}
                    field="description"
                    label={t('screens:managements.data.description')}
                    required
                  >
                    {/* set default value egual to "president" */}
                    <Input name="description" disabled />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormControl
                    error={errors.initial_date}
                    field="initial_date"
                    label={t('screens:managements.data.initial_date')}
                    required
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      name="initial_date"
                      placeholder={t('messages:select')}
                      locale={locale}
                      disabled={permissions && !permissions.includes('@user/edit')}
                    />
                  </FormControl>
                </Col>
                {/* <Col span={12}>
                  <FormControl
                    error={errors.final_date}
                    field="final_date"
                    label={t('screens:managements.data.final_date')}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      name="final_date"
                      nullable
                      placeholder={t('messages:select')}
                      locale={locale}
                      disabled={permissions && !permissions.includes('@user/edit')}
                    />
                  </FormControl>
                </Col> */}
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
