/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Input, Form, Radio } from 'formik-antd';
import MaskedInput from 'antd-mask-input';
import { FaMale, FaBuilding, FaUser, FaTruck, FaUserFriends } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Spin, message, Col, Divider } from 'antd';
import * as Yup from 'yup';
// import axios from 'axios';
import * as cep from 'cep-promise';
import { useHistory } from 'react-router-dom';

import Row from '~/components/Row';

import Button from '~/components/Button';
import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import errorHandler from '~/Utils/errorHandler';
import { decrypt, validateCpf, validateCnpj } from '~/Utils/index';
import { DivTitle } from './styles';

const initialValues = {
  business: false,
  fantasy_name: '',
  company_name: '',
  type: 'customer',
  state_reg: '',
  city_reg: '',
  email: '',
  site: '',
  phone: '',
  phone_extension: '',
  fax: '',
  fax_extension: '',
  contact: {
    provider_id: '',
    name: '',
    department: '',
    email: '',
    phone: '',
    phone_extension: '',
    mobile: '',
  },
  address: {
    zipcode: '',
    address: '',
    address_number: '',
    address_complement: '',
    city: '',
    state: '',
    country: '',
    neighborhood: '',
    phone: '',
    phone_extension: '',
    fax: '',
    fax_extension: '',
    mobile: '',
  },
};

export default function ProviderForm({ match }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));
  const [loading, setLoading] = useState(false);
  const [providerType, setProviderType] = useState(false);
  const [recordData, setRecordData] = useState(initialValues);

  const handleTypeChange = ({ target }) => {
    setProviderType(target.value);
  };

  const fetchRecordData = async () => {
    try {
      const { id } = match.params;
      if (id) {
        const response = await api.get(`/providers/${id}`);
        const data = JSON.parse(response.data);
        setRecordData(data);
        setProviderType(data.business);
      } else {
        setRecordData(initialValues);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const setSSNErrors = (setErrors, msg) => {
    message.error(msg);
    setErrors({
      ssn: msg,
    });
  };

  const validateSsn = async (values, setErrors) => {
    const ssn = values.ssn.replace(/[^0-9,]*/g, '');
    if (values.business === false && !validateCpf(ssn)) {
      setSSNErrors(setErrors, 'CPF inválido');
      return false;
    }
    if (values.business === true && !validateCnpj(ssn)) {
      setSSNErrors(setErrors, 'CNPJ inválido');
      return false;
    }
    return true;
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    if (await validateSsn(values, setErrors)) {
      try {
        if (values.id) {
          await api.put(`/providers/${values.id}`, values);
          message.success(t('messages:successUpdateproviders'));
        } else {
          await api.post('/providers', values);
          message.success(t('messages:successProviders'));
        }

        history.push(`/clientes`);
      } catch (error) {
        setErrors(errorHandler(error));
      }
    }
    setLoading(false);
  };

  const handleFindAddress = async (value, values) => {
    setLoading(true);
    try {
      const address = await cep(value);

      const data = {
        ...values,
        address: {
          ...values.address,
          zipcode: value,
          city: address.city,
          state: address.state,
          neighborhood: address.neighborhood,
          address: address.street,
          country: 'Brasil',
        },
      };

      setRecordData(data);
    } catch (error) {
      message.error('Endereço não localizado!');
    }
    setLoading(false);
  };

  const handleFindCompany = async (cnpj, values) => {
    setLoading(true);
    try {
      // const { data } = await axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`);
      const { data } = await api.get(`/cnpj/${cnpj}`);
      if (data.status === 'ERROR') {
        message.error(data.message);
        setLoading(false);
        return;
      }

      const foundData = {
        ...values,
        ssn: cnpj,
        fantasy_name: data.fantasia,
        company_name: data.nome,
        phone: data.telefone,
      };

      setRecordData(foundData);
      handleFindAddress(data.cep.replace(/[^0-9,]*/g, ''), foundData);

      message.success('Empresa encontrada!');
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const handleBack = () => {
    history.push('/clientes');
  };

  const PhoneInput = (inputName, inputPlaceholder, inputMask) => (
    <Field
      name={inputName}
      render={({ field }) => (
        <MaskedInput
          mask={inputMask}
          {...field}
          id={inputName}
          placeholder={inputPlaceholder}
          disabled={permissions && !permissions.includes('@provider/edit')}
        />
      )}
    />
  );

  const CPFInput = (
    <Field
      name="ssn"
      render={({ field }) => <MaskedInput mask="111.111.111-11" {...field} id="ssn" placeholder="___.___.___-__" />}
    />
  );

  const CNPJInput = (values) => (
    <Field
      name="ssn"
      render={({ field }) => (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'rgba(0, 0, 0, 0.65)',
            fontWeight: 'normal',
            fontSize: '14px',
            textAlign: 'left',
            backgroundColor: '#fafafa',
            border: '1px solid #d9d9d9',
            borderRight: '1px solid #d9d9d9',
            borderRadius: '5px',
            WebkitTransition: 'all 0.3s',
            transition: 'all 0.3s',
          }}
        >
          <MaskedInput
            style={{ border: 'none' }}
            mask="11.111.111/1111-11"
            {...field}
            id="ssn"
            placeholder="__.___.___/____-__"
            onChange={(e) => {
              const cnpj = e.target.value.replace(/[^0-9,]*/g, '');
              if (cnpj.length === 14) {
                handleFindCompany(cnpj, values);
              }
            }}
          />
        </div>
      )}
    />
  );

  useEffect(() => {
    fetchScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providersSchema = Yup.object().shape({
    ssn: Yup.string().required(),
    business: Yup.boolean().required(),
    fantasy_name: Yup.string().nullable(),
    company_name: Yup.string().nullable(),
    type: Yup.string()
      .required()
      .matches(/(provider|customer|both)/, 'Insira um tipo válido!', { excludeEmptyString: true }),
    state_reg: Yup.string().nullable(),
    city_reg: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
    site: Yup.string()
      .nullable()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Insira um link válido!'
      ),
    phone: Yup.string().nullable(),
    phone_extension: Yup.string().nullable(),
    fax: Yup.string().nullable(),
    fax_extension: Yup.string().nullable(),
    contact: Yup.object().shape({
      name: Yup.string().required(),
      department: Yup.string().nullable(),
      email: Yup.string().email().required(),
      phone: Yup.string().nullable(),
      phone_extension: Yup.string().nullable(),
      mobile: Yup.string().nullable(),
    }),
    address: Yup.object().shape({
      zipcode: Yup.string().required(),
      address: Yup.string().required(),
      address_number: Yup.string().required(),
      address_complement: Yup.string().nullable(),
      city: Yup.string().required(),
      state: Yup.string().required().max(2),
      country: Yup.string().required(),
      neighborhood: Yup.string().required(),
      phone: Yup.string().nullable(),
      phone_extension: Yup.string().nullable(),
      fax: Yup.string().nullable(),
      fax_extension: Yup.string().nullable(),
      mobile: Yup.string().nullable(),
    }),
  });
  return (
    <DefaultLayout>
      <PageTitle
        title={t('screens:providers.title')}
        subtitle={recordData.name ? `editando - ${recordData.name}` : null}
      />
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={recordData}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={providersSchema}
      >
        {({ errors, values }) => (
          <Spin spinning={loading}>
            <Form>
              <Input type="hidden" name="id" />
              <Box>
                <DivTitle>{t('screens:providers.data.data')}</DivTitle>

                <Row>
                  <FormControl
                    error={errors.business}
                    cols={{ xs: 4 }}
                    field="business"
                    label={t('screens:providers.data.business')}
                    required
                  >
                    <Radio.Group name="business" buttonStyle="solid" onChange={handleTypeChange}>
                      <Radio.Button value={false}>
                        <FaMale /> {t('screens:providers.data.type.person')}
                      </Radio.Button>
                      <Radio.Button value>
                        <FaBuilding /> {t('screens:providers.data.type.business')}
                      </Radio.Button>
                    </Radio.Group>
                  </FormControl>
                  <FormControl cols={{ xs: 8 }} error={errors.type} field="type" label={t('fields:type')} required>
                    <Radio.Group name="type" buttonStyle="solid">
                      <Radio.Button value="customer">
                        <FaUser /> {t('screens:providers.data.type.customer')}
                      </Radio.Button>
                      <Radio.Button value="provider">
                        <FaTruck /> {t('screens:providers.data.type.provider')}
                      </Radio.Button>
                      <Radio.Button value="both">
                        <FaUserFriends /> {t('screens:providers.data.type.both')}
                      </Radio.Button>
                    </Radio.Group>
                  </FormControl>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.ssn}
                      field="ssn"
                      label={providerType ? t('screens:providers.data.ssn.cnpj') : t('screens:providers.data.ssn.cpf')}
                      required
                    >
                      {providerType ? CNPJInput(values) : CPFInput}
                    </FormControl>
                    {providerType ? (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.company_name}
                        field="company_name"
                        label={t('screens:providers.data.company_name')}
                      >
                        <Input name="company_name" disabled={permissions && !permissions.includes('@provider/edit')} />
                      </FormControl>
                    ) : (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.phone}
                        field="phone"
                        label={t('screens:providers.data.phone')}
                      >
                        {PhoneInput('phone', '(__) ____-____', '(11) 1111-1111')}
                      </FormControl>
                    )}
                    {providerType ? (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.fantasy_name}
                        field="fantasy_name"
                        label={t('screens:providers.data.fantasy_name')}
                      >
                        <Input name="fantasy_name" disabled={permissions && !permissions.includes('@provider/edit')} />
                      </FormControl>
                    ) : (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.phone_extension}
                        field="phone_extension"
                        label={t('screens:providers.data.phone_extension')}
                      >
                        <Input
                          name="phone_extension"
                          disabled={permissions && !permissions.includes('@provider/edit')}
                        />
                      </FormControl>
                    )}
                    {providerType && (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.state_reg}
                        field="state_reg"
                        label={t('screens:providers.data.state_reg')}
                      >
                        <Input name="state_reg" disabled={permissions && !permissions.includes('@provider/edit')} />
                      </FormControl>
                    )}
                    {providerType && (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.city_reg}
                        field="city_reg"
                        label={t('screens:providers.data.city_reg')}
                      >
                        <Input name="city_reg" disabled={permissions && !permissions.includes('@provider/edit')} />
                      </FormControl>
                    )}
                  </Col>
                  <Col xs={12}>
                    {providerType && (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.phone}
                        field="phone"
                        label={t('screens:providers.data.phone')}
                      >
                        {PhoneInput('phone', '(__) ____-____', '(11) 1111-1111')}
                      </FormControl>
                    )}
                    {providerType && (
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.phone_extension}
                        field="phone_extension"
                        label={t('screens:providers.data.phone_extension')}
                      >
                        <Input
                          name="phone_extension"
                          disabled={permissions && !permissions.includes('@provider/edit')}
                        />
                      </FormControl>
                    )}
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.fax}
                      field="fax"
                      label={t('screens:providers.data.fax')}
                    >
                      <Input name="fax" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.fax_extension}
                      field="fax_extension"
                      label={t('screens:providers.data.fax_extension')}
                    >
                      <Input name="fax_extension" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.site}
                      field="site"
                      label={t('screens:providers.data.site')}
                    >
                      <Input name="site" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.email}
                      field="email"
                      label={t('screens:providers.data.email')}
                    >
                      <Input name="email" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Divider style={{ margin: '2rem 0 2rem 0' }} />
                </Row>
                <Row>
                  <Col xs={12}>
                    <DivTitle>{t('screens:providers.data.address')}</DivTitle>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.zipcode}
                      field="address.zipcode"
                      label={t('screens:providers.data.zipcode')}
                      required
                    >
                      <Field
                        name="address.zipcode"
                        render={({ field }) => (
                          <MaskedInput
                            mask="11.111-111"
                            {...field}
                            disabled={permissions && !permissions.includes('@provider/edit')}
                            placeholder="__.___-__"
                            autoComplete="dontshow"
                            onBlur={(e) => {
                              const zipcode = e.target.value.replace(/[^0-9,]*/g, '');
                              if (zipcode.length === 8) {
                                handleFindAddress(zipcode, values);
                              }
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.address}
                      field="address.address"
                      label={t('screens:providers.data.address')}
                      required
                    >
                      <Input name="address.address" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.address_number}
                      field="address.address_number"
                      label={t('screens:providers.data.address_number')}
                      required
                    >
                      <Input
                        name="address.address_number"
                        disabled={permissions && !permissions.includes('@provider/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.address_complement}
                      field="address.address_complement"
                      label={t('screens:people.data.address_complement')}
                    >
                      <Input
                        name="address.address_complement"
                        disabled={permissions && !permissions.includes('@provider/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.city}
                      field="address.city"
                      label={t('screens:providers.data.city')}
                      required
                    >
                      <Input name="address.city" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.state}
                      field="address.state"
                      label={t('screens:providers.data.state')}
                      required
                    >
                      <Input name="address.state" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.country}
                      field="address.country"
                      label={t('screens:providers.data.country')}
                      required
                    >
                      <Input name="address.country" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.address && errors.address.neighborhood}
                      field="address.neighborhood"
                      label={t('screens:providers.data.neighborhood')}
                      required
                    >
                      <Input
                        name="address.neighborhood"
                        disabled={permissions && !permissions.includes('@provider/edit')}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12}>
                    <DivTitle>{t('screens:providers.data.contact')}</DivTitle>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.contact && errors.contact.name}
                      field="contact.name"
                      label={t('fields:name')}
                      required
                    >
                      <Input name="contact.name" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.contact && errors.contact.department}
                      field="contact.department"
                      label={t('screens:providers.data.department')}
                    >
                      <Input
                        name="contact.department"
                        disabled={permissions && !permissions.includes('@provider/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.contact && errors.contact.email}
                      field="contact.email"
                      label={t('screens:providers.data.email')}
                      required
                    >
                      <Input name="contact.email" disabled={permissions && !permissions.includes('@provider/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.contact && errors.contact.phone}
                      field="contact.phone"
                      label={t('screens:providers.data.phone')}
                    >
                      {PhoneInput('contact.phone', '(__) ____-____', '(11) 1111-1111')}
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.contact && errors.contact.phone_extension}
                      field="contact.phone_extension"
                      label={t('screens:providers.data.phone_extension')}
                    >
                      <Input
                        name="contact.phone_extension"
                        disabled={permissions && !permissions.includes('@provider/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.contact && errors.contact.mobile}
                      field="contact.mobile"
                      label={t('screens:providers.data.mobile')}
                    >
                      {PhoneInput('contact.mobile', '(__) _____-____', '(11) 11111-1111')}
                    </FormControl>
                  </Col>
                  <Col xs={8} />
                </Row>
                <Row>
                  <FormActions>
                    <Can permission="@provider/edit">
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Can>
                    <Button onClick={handleBack}>Cancelar</Button>
                  </FormActions>
                </Row>
              </Box>
            </Form>
          </Spin>
        )}
      </Formik>
    </DefaultLayout>
  );
}
