import React, { useState, useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import { crypt, decrypt, clearStorage } from '~/Utils/index';
import errorHandler from '~/Utils/errorHandler';
import cities from '~/data/cities.json';
import nationalities from '~/data/nationalities.json';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const Login = async (values) => {
    setLoading(true);
    try {
      const response = await api.post('/auth', values);
      const data = JSON.parse(response.data);
      api.defaults.headers.Authorization = `Bearer ${data.token}`;

      setUser(data.user);

      const cryptedUser = crypt(JSON.stringify(data.user));
      const cryptedToken = crypt(data.token);
      const cryptedVersion = crypt(data.version);
      const cryptedNeeds = crypt(JSON.stringify({ cities, needs: data.needs, nationalities }));

      localStorage.setItem('@App:needs', cryptedNeeds);
      localStorage.setItem('@App:user', cryptedUser);
      localStorage.setItem('@App:token', cryptedToken);
      localStorage.setItem('@App:version', cryptedVersion);

      message.success(t('messages:success'));
    } catch (error) {
      const errors = errorHandler(error);

      if (errors.email) {
        message.error(errors.email);
      }
    }
    setLoading(false);
  };

  async function Logout() {
    try {
      setUser(null);
      clearStorage();

      message.success(t('messages:successLogout'));
      history.push('/');
      await api.post('/auth/logout');
    } catch (error) {
      errorHandler(error);
    }
  }

  useEffect(() => {
    const cryptedUser = localStorage.getItem('@App:user');
    const storagedUser = decrypt(cryptedUser);

    const cryptedToken = localStorage.getItem('@App:token');
    const storagedToken = decrypt(cryptedToken);

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  return (
    <AuthContext.Provider value={{ signed: !!user, user, loading, Login, Logout }}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: <></>,
};
