import React, { useState, useEffect } from 'react';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaEye, FaFileInvoice } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, message, Popconfirm, Switch } from 'antd';
import { normalizeString, getSuccessMessageByModule } from '~/Utils';

import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import ChargeForm from './ChargeForm';
import AnnuityForm from './form';
import { Global } from './styles';
import DefaultLayout from '../_layouts/full';

export default function Annuity() {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showChargeForm, setShowChargeForm] = useState(false);

  const { t } = useTranslation();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/annuities');
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    setShowForm(true);
  };

  const handleEdit = (id) => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`annuities/${id}`);
      message.success(getSuccessMessageByModule(t('screens:annuities.titleSingular'), t('messages:successDeletedFem')));
      fetchRecords();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeActive = async (record) => {
    setLoading(true);
    try {
      await api.put(`/annuities/${record.id}`, record);
      fetchRecords();
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showForm) {
      setSelectedRecord(null);
      fetchRecords();
    }
  }, [showForm]);

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      const results = recordData.filter(
        (item) =>
          item.description.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) !== -1 ||
          normalizeString(item.description).toLowerCase().indexOf(searchTerm.toString().toLowerCase()) !== -1 ||
          item.reference_year.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) !== -1
      );
      setTableData(results);
    } else {
      setTableData(recordData);
    }
  }, [recordData, searchTerm]);

  const tableColumns = [
    {
      title: t('screens:annuities.data.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:annuities.data.reference_year'),
      dataIndex: 'reference_year',
      key: 'reference_year',
      render: (record) => record && record.split('-')[0],
    },
    {
      title: t('fields:cost_center_id'),
      dataIndex: 'cost_center_id',
      key: 'cost_center_id',
      render: (record, text) => text.cost_center && text.cost_center.name,
    },
    {
      title: t('fields:bank_account_id'),
      dataIndex: 'bank_account_id',
      key: 'bank_account_id',
      render: (record, text) => text.bank_account && text.bank_account.description,
    },
    {
      title: t('screens:annuities.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@annuity/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@annuity/edit">
            <Can permission="@annuity/view">
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@annuity/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
          <Can permission="@annuity/edit">
            <Switch
              name="active"
              size="small"
              style={{ marginTop: '5px' }}
              checked={record.active}
              onChange={(e) => {
                record.active = e;
                handleChangeActive(record);
              }}
            />
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:annuities.title')}>
        <Can permission="@annuity/charge">
          <Button
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
            loading={loading}
            color="primary"
            onClick={() => {
              setShowChargeForm(true);
            }}
          >
            <FaFileInvoice />
            {t('screens:annuities.charges')}
          </Button>
        </Can>
        <Can permission="@annuity/edit">
          <Button style={{ display: 'inline-block' }} loading={loading} color="primary" onClick={handleNew}>
            <FaPlus />
            {t('screens:annuities.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />

        <Global />

        <ChargeForm visible={showChargeForm} onClose={() => setShowChargeForm(false)} />
        <AnnuityForm visible={showForm} annuityID={selectedRecord} onClose={() => setShowForm(false)} />
      </Box>
    </DefaultLayout>
  );
}
