import React, { useState, useEffect } from 'react';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaScroll, FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, message, Popconfirm } from 'antd';
import { normalizeString, getSuccessMessageByModule } from '~/Utils';

import api from '~/services/api';
import SubscriptionTable from '~/components/Subscription';
import errorHandler from '~/Utils/errorHandler';

import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';

import DefaultLayout from '../_layouts/full';
import { Global } from './styles';
import CourseForm from './form';

export default function Course() {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showSubscriptionsForm, setShowSubscriptionsForm] = useState(false);

  const { t } = useTranslation();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/courses');
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    setShowForm(true);
  };

  const handleEdit = (id) => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`courses/${id}`);
      message.success(getSuccessMessageByModule(t('screens:courses.titleSingular'), t('messages:successDeleted')));
      await fetchRecords();
    } catch (error) {
      if (error.response.status === 409) {
        message.warning('Não é possível remover um curso com inscritos!');
      }
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSubscriptions = (id) => {
    setSelectedRecord(id);
    setShowSubscriptionsForm(true);
  };

  useEffect(() => {
    if (!showForm && !showSubscriptionsForm) {
      setSelectedRecord(null);
      fetchRecords();
    }
  }, [showForm, showSubscriptionsForm]);

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      const results = recordData.filter(
        (item) =>
          item.description.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          normalizeString(item.description).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.professor.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          normalizeString(item.professor).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          (item.congress && item.congress.description.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.congress &&
            normalizeString(item.congress.description).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
      );
      setTableData(results);
    } else {
      setTableData(recordData);
    }
  }, [recordData, searchTerm]);

  const tableColumns = [
    {
      title: t('fields:congress_id'),
      dataIndex: 'congress_id',
      key: 'congress_id',
      render: (record, text) => text.congress && text.congress.description,
    },
    {
      title: t('fields:description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:courses.data.professor'),
      dataIndex: 'professor',
      key: 'professor',
    },
    {
      title: t('fields:date_time'),
      dataIndex: 'date_time',
      key: 'date_time',
      render: (record) => new Date(record).toLocaleString(),
    },
    {
      title: t('screens:courses.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@course/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@course/edit">
            <Can permission="@course/view">
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@congress/edit">
            <Button onClick={() => handleSubscriptions(record.id)} size="small" title="Inscritos">
              <FaScroll />
            </Button>
          </Can>
          <Can permission="@course/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:courses.title')}>
        <Can permission="@course/edit">
          <Button loading={loading} color="primary" onClick={handleNew}>
            <FaPlus />
            {t('screens:courses.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />

        <Global />

        <CourseForm visible={showForm} courseID={selectedRecord} onClose={() => setShowForm(false)} />
        <SubscriptionTable
          visible={showSubscriptionsForm}
          isCongress={false}
          relatedID={selectedRecord}
          onClose={() => setShowSubscriptionsForm(false)}
        />
      </Box>
    </DefaultLayout>
  );
}
