/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';

const ResumesCharts = React.memo(({ resumes }) => {
  const [chartData, setChartData] = useState(null);

  const getDataInfo = (data, labelPrefix) => ({
    label: `${labelPrefix} (${data.qty ?? 0} - ${data.percentage ?? 0}%)`,
    data: data.qty ?? 0,
  });

  useEffect(() => {
    try {
      const {
        approved,
        nonEvaluating,
        onEvaluating,
        pending,
        reproved,
        waiting
      } = resumes;

      const infoLabels = [
        'Em avaliação', 'Sem avaliador', 'Aprovados', 'Pendentes', 'Reprovados', 'Aguardando correção'
      ];

      const dataValues = [
        onEvaluating, nonEvaluating, approved, pending, reproved, waiting
      ];

      const labels = [];
      const data = [];
      const backgroundColor = [
        'rgba(75, 192, 192, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(128, 128, 0, 0.7)',
        'rgba(153, 102, 255, 0.7)'
      ];
      const borderColor = backgroundColor.map(color => color.replace('0.7', '1'));

      dataValues.forEach((item, index) => {
        const info = getDataInfo(item, infoLabels[index]);
        labels.push(info.label);
        data.push(info.data);
      });

      setChartData({
        labels,
        datasets: [{
          label: 'Inscrições',
          data,
          backgroundColor,
          borderColor,
          borderWidth: 1,
        }],
      });
    } catch (error) {
      console.error('Error processing the data', error);
    }
  }, [resumes]);

  return (
    <div>
      <p style={{ textAlign: 'center', marginBottom: 10,fontSize: 12, color: '#93c051', paddingTop: 15 }}>Resumos</p>
      {chartData ? <Pie data={chartData} /> : <p>Loading...</p>}
      <p style={{ textAlign: 'center', marginBottom: 10,fontSize: 12, color: '#93c051', paddingTop: 15 }}>Total Resumos: {resumes?.allResumes ?? 'Loading...'}</p>
    </div>
  );
});

export default ResumesCharts;
