/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { decrypt, getSuccessMessageByModule } from '~/Utils/index';

import api from '~/services/api';

const initialValues = {
  name: '',
  costs: [],
};

export default function CostCenterForm({ visible, onClose, costCenterID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [costsOptions, setCostsOptions] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    delete values.isEdit;
    try {
      if (values.id) {
        await api.put(`/costCenters/${values.id}`, values);
        message.success(
          getSuccessMessageByModule(t('screens:costCenters.titleSingular'), t('messages:successUpdated'))
        );
      } else {
        await api.post('/costCenters', values);
        message.success(
          getSuccessMessageByModule(t('screens:costCenters.titleSingular'), t('messages:successCreated'))
        );
      }
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const { data: costs } = await api.get('/costCenters/costs/all');
      setCostsOptions(costs);

      if (!costCenterID) {
        setRecordData(initialValues);
      } else {
        const response = await api.get(`/costCenters/${costCenterID}`);
        const data = JSON.parse(response.data);
        setRecordData({
          ...data,
          costs: data.costs.map((item) => item.name),
        });
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const costCenterschema = Yup.object().shape({
    name: Yup.string().required(),
    costs: Yup.array().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={costCenterschema}
    >
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.name}` : t('screens:costCenters.title')}
          onCancel={onClose}
          style={{ maxWidth: '650px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@costCenter/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.name}
                      field="name"
                      label={t('screens:costCenters.data.name')}
                      required
                    >
                      <Input name="name" disabled={permissions && !permissions.includes('@costCenter/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.costs}
                      field="costs"
                      label={t('screens:costCenters.data.costs')}
                      required
                    >
                      <Select
                        name="costs"
                        disabled={permissions && !permissions.includes('@costCenter/edit')}
                        allowClear
                        showArrow
                        showSearch
                        mode="tags"
                        optionFilterProp="children"
                      >
                        {costsOptions &&
                          costsOptions.map((item) => (
                            <Select.Option key={item.name} value={item.name}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
