import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import api from '~/services/api';

const RegisteredChart = () => {
  const [chartData, setChartData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await api
      .get('/dashboard/data')
      .then((response) => {

        // use destructuring to get the data.people from the response
        const { data } = response;
        const { people } = data;

        const students = {
          label: `Regulares (${people.status.regular}%)`,
          data: people.status.regular,
        };
        const professionals = {
          label: `Inativos (${people.status.inactive}%)`,
          data: people.status.inactive,
        };
        const posGraduates = {
          label: `Pendentes (${people.status.pending}%)`,
          data: people.status.pending,
        };

        const formattedData = {
          labels: [students.label, professionals.label, posGraduates.label],
          datasets: [
            {
              label: 'Pessoas Registradas',
              data: [
                students.data,
                professionals.data,
                posGraduates.data,
              ],
              backgroundColor: [
                'rgba(75, 192, 192, 0.7)',
                'rgba(255, 206, 86, 0.7)',
                'rgba(255, 99, 132, 0.7)',
              ],
              borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };
        setChartData(formattedData);
      })
      .catch((error) => {
        console.error('Error loading the data', error);
      });
  }, []);

  return <div>{chartData ? <Pie data={chartData} /> : <p>Loading...</p>}</div>;
};

export default RegisteredChart;
