import React from 'react';

const ConselhoFiscalPage = () => (
  <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
    <h1>Conselho Fiscal</h1>

    <p>A prestação de contas da SBFV é feita de maneira rápida e prática.</p>
    <p>
      No menu <strong>RELATÓRIOS</strong> você terá:
    </p>

    <div>
      <ul style={{ listStyle: 'circle', marginLeft: '20px' }}>
        <li style={{ marginBottom: '10px' }}>
          <strong>Descrição do Movimento</strong>
          <br />
          Resumo do que se refere a movimentação.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <strong>Pessoa/Fornecedor</strong>
          <br />
          Nome do associado ou empresa.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <strong>De / Até</strong>
          <br />
          Filtro para o período que deseja ver na tela.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <strong>Conta bancária</strong><br />
          <ul style={{ listStyle: 'square', marginLeft: '20px' }}>
            <li style={{ marginBottom: '10px' }}>Conta Santander - esta é a única conta de onde é feito os pagamentos, é somente dela que o recurso sai da SBFV.</li>
            <li style={{ marginBottom: '10px' }}>ContaMax Santander - esta conta é de Aplicação Financeira automática. Todo saldo da Conta Santander ao final do dia vai para ela se positivo, ou sai dela se negativo. Ao final de cada mês tem a soma.</li>
            <li style={{ marginBottom: '10px' }}>PagSeguro - esta conta é onde recebemos os valores pagos pelo sistema. O recurso entra on-line e sai apenas para a Conta Santander.</li>
            <li style={{ marginBottom: '10px' }}>Santander Internacional - esta conta são os recursos recebidos do exterior.</li>
          </ul>
        </li>
        <li style={{ marginBottom: '10px' }}>
          <strong>Centro de Custo</strong>
          <br />
          Este campo é utilizado para filtrar a origem do Custo da receita/despesa.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <strong>Custo</strong>
          <br />
          Um filtro mais específico da receita/despesa.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <strong>Valor</strong>
          <br />
          Um filtro para buscar um valor específico.
        </li>
        <li style={{ marginBottom: '10px' }}>
          <strong>Extrato Bancário</strong>
          <br />
          Neste campo consta o texto conforme está no extrato bancário, ele ajuda a confirmar a receita/despesa com o extrato.
        </li>
      </ul>
    </div>

    <p>
      É possível fazer a exportação para uma planilha no botão <strong>EXPORTAR</strong> no canto superior direito.
    </p>

    <p>
      No último dia de cada mês existe um lançamento &quot;Tarifas e taxas - Extrato XXX/XX em anexo&quot; onde é possível
      acessar o extrato da &apos;Conta Santander&apos; para conferência.
    </p>
  </div>
);

export default ConselhoFiscalPage;
