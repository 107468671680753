/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, DatePicker, Select, Switch } from 'formik-antd';
import { Modal, message, Spin, Col, Upload } from 'antd';
import { FaUpload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import moment from 'moment';
import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import Can from '~/components/Can';
import Thumb from '~/components/Thumb';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Button from '~/components/Button';
import { decrypt, getBase64, getSuccessMessageByModule } from '~/Utils/index';
import api from '~/services/api';

const initialValues = {
  description: '',
  free: false,
  cost_center_id: '',
  bank_account_id: '',
  organizers: [], // organizer_id: '',
  areas: [],
  translated: false,
  initial_date: '',
  final_date: '',
  web_date: '',
  place: '',
  info: '',
  url: '',
  'tradutor?': false,
  accept_resumes: false,
};

export default function CongressForm({ visible, onClose, congressID, setUpdated }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [file, setFile] = useState(null);
  const [deleteOldFile, setDeleteOldFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [bankAccountOptions, setbankAccountOptions] = useState([]);
  const [organizersOptions, setOrganizersOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchCostCenters = async () => {
    try {
      const { data } = await api.get('/costCenters');
      setCostCenterOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchOrganizers = async () => {
    try {
      const { data } = await api.get('/users');
      setOrganizersOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchAreas = async () => {
    try {
      const { data } = await api.get('/areas');
      setAreasOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const { data } = await api.get('/bankAccounts');
      setbankAccountOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    values.initial_date = moment(values.initial_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    values.final_date = moment(values.final_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    values.web_date = moment(values.web_date).format('YYYY-MM-DD');

    if (values.free === true) {
      values.cost_center_id = null;
      values.bank_account_id = null;
    }

    delete values.isEdit;
    try {
      if (values.id) {
        if (deleteOldFile === true) {
          await api.delete(`/files/${values.id}/flyer`);
          values.flyer_id = null;
        }
        await api.put(`/congresses/${values.id}`, values);
        message.success(getSuccessMessageByModule(t('screens:congresses.titleSingular'), t('messages:successUpdated')));
        if (file && file.originFileObj) {
          const files = new FormData();
          files.append('file', file.originFileObj);
          await api.post(`/files/${values.id}/flyer`, files);
        }
      } else {
        const { data } = await api.post('/congresses', values);
        const congress = JSON.parse(data);
        message.success(getSuccessMessageByModule(t('screens:congresses.titleSingular'), t('messages:successCreated')));
        if (file && file.originFileObj) {
          const files = new FormData();
          files.append('file', file.originFileObj);
          await api.post(`/files/${congress.id}/flyer`, files);
        }
      }
      setFile(null);
      setDeleteOldFile(null);
      setUpdated(true);
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCostCenters(), fetchOrganizers(), fetchBankAccounts(), fetchAreas()]);
      if (!congressID) {
        setRecordData(initialValues);
      } else {
        const response = await api.get(`/congresses/${congressID}`);
        const data = JSON.parse(response.data);
        setFile(data.flyer);
        document.getElementById('uploadButton').style.cursor = data.flyer !== null ? 'no-drop' : 'pointer';
        setRecordData({
          ...data,
          areas: data.areas.map(item => item.id),
          organizers: data.organizers.map(item => item.id),
        });
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleRemoveFile = () => {
    document.getElementById('uploadButton').style.cursor = 'pointer';
    setFile(null);
    setDeleteOldFile(true);
  };

  const beforeUpload = newFile => {
    setLoading(true);

    let justOne = true;
    if (file) {
      justOne = false;
      message.error('Você só pode anexar 1 arquivo');
    }

    const isValid =
      newFile.type === 'image/jpeg' ||
      newFile.type === 'image/jpg' ||
      newFile.type === 'image/png' ||
      newFile.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }
    const isLt2M = newFile.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 20MB!');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }

    setLoading(false);
    return isValid && isLt2M && justOne;
  };

  const handleUpload = event => {
    setLoading(true);

    if (!event.file.originFileObj) {
      setLoading(false);
      return;
    }

    let extension = '';
    let name = '';
    if (event.file.name.substring(event.file.name.length - 4) === 'jpeg') {
      extension = event.file.name.substring(event.file.name.length - 5);
      name = event.file.name.replaceAll('.', '');
      name = name.substring(0, name.length - 4);
    } else {
      extension = event.file.name.substring(event.file.name.length - 4);
      name = event.file.name.replaceAll('.', '');
      name = name.substring(0, name.length - 3);
    }
    event.file.name = name + extension;

    if (!event.file.id) {
      getBase64(event.file.originFileObj, image => {
        event.file.base64Url = image;
      });
    }
    document.getElementById('uploadButton').style.cursor = 'no-drop';
    setTimeout(() => {
      setFile(event.file);
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const congressSchema = Yup.object().shape({
    description: Yup.string().required(),
    info: Yup.string().required(),
    place: Yup.string().nullable(),
    web_date: Yup.date().required(),
    url: Yup.string().nullable().url(),
    initial_date: Yup.date().required(),
    final_date: Yup.date().required(),
    cost_center_id: Yup.number().integer().nullable(),
    bank_account_id: Yup.number().integer().nullable(),
    areas: Yup.array().nullable(),
    // organizer_id: Yup.number().integer().nullable(),
    max_subscriptions: Yup.number().when([], {
      is: () => recordData?.paid_subscriptions !== undefined,
      then: Yup.number().min(
        recordData?.paid_subscriptions,
        `O número máximo de inscrições pagas tem que ser maior/igual ao número de inscrições pagas já existentes. (${recordData?.paid_subscriptions} pagas)`
      ),
      otherwise: Yup.number().required('Este campo é obrigatório'),
    }),
    event_duration: Yup.string().nullable(),
    event_date: Yup.string().nullable(),
    accept_resumes: Yup.bool().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={congressSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, values }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.description}` : t('screens:congresses.title')}
          onCancel={() => {
            onClose();
            setFile(null);
          }}
          style={{ maxWidth: '1100px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@congress/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={() => {
                onClose();
                setFile(null);
              }}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.description}
                      field="description"
                      label={t('screens:congresses.data.description')}
                      required
                    >
                      <Input name="description" disabled={permissions && !permissions.includes('@congress/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.place}
                      field="place"
                      label={t('screens:congresses.data.place')}
                    >
                      <Input name="place" disabled={permissions && !permissions.includes('@congress/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.areas}
                      field="areas"
                      label={t('screens:congresses.data.areas')}
                    >
                      <Select
                        name="areas"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                        allowClear
                        showArrow
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                      >
                        {areasOptions &&
                          areasOptions.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.url}
                      field="url"
                      label={t('screens:congresses.data.url')}
                    >
                      <Input name="url" disabled={permissions && !permissions.includes('@congress/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.max_subscriptions}
                      field="max_subscriptions"
                      label={`${t('screens:congresses.data.max_subscriptions')} ${
                        values.free === true ? '' : '(pagas)'
                      }`}
                    >
                      <Input
                        name="max_subscriptions"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.event_duration}
                      field="event_duration"
                      label={t('screens:congresses.data.event_duration')}
                    >
                      <Input name="event_duration" disabled={permissions && !permissions.includes('@congress/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.event_date}
                      field="event_date"
                      label={t('screens:congresses.data.event_date')}
                    >
                      <Input name="event_date" disabled={permissions && !permissions.includes('@congress/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.info}
                      field="info"
                      label={t('screens:congresses.data.info')}
                      required
                    >
                      <Input.TextArea
                        rows={4}
                        name="info"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.initial_date}
                      field="initial_date"
                      label={t('screens:congresses.data.initial_date')}
                      required
                    >
                      <DatePicker
                        format="DD/MM/YYYY HH:mm:ss"
                        showTime
                        name="initial_date"
                        placeholder={t('messages:select')}
                        locale={locale}
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.final_date}
                      field="final_date"
                      label={t('screens:congresses.data.final_date')}
                      required
                    >
                      <DatePicker
                        format="DD/MM/YYYY HH:mm:ss"
                        showTime
                        name="final_date"
                        placeholder={t('messages:select')}
                        locale={locale}
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.web_date}
                      field="web_date"
                      label={t('screens:congresses.data.web_date')}
                      required
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        name="web_date"
                        placeholder={t('messages:select')}
                        locale={locale}
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl cols={{ xs: 3 }} field="free" label={t('screens:congresses.data.free')}>
                      <Switch
                        name="free"
                        checkedChildren={t('messages:yes')}
                        unCheckedChildren={t('messages:no')}
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl cols={{ xs: 3 }} field="translated" label={t('screens:congresses.data.translated')}>
                      <Switch
                        name="translated"
                        checkedChildren={t('messages:yes')}
                        unCheckedChildren={t('messages:no')}
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 3 }}
                      field="accept_resumes"
                      label={t('screens:congresses.data.accept_resumes')}
                    >
                      <Switch
                        name="accept_resumes"
                        checkedChildren={t('messages:yes')}
                        unCheckedChildren={t('messages:no')}
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.bank_account_id}
                      field="bank_account_id"
                      label={t('fields:bank_account_id')}
                    >
                      <Select
                        name="bank_account_id"
                        disabled={values.free === true || (permissions && !permissions.includes('@congress/edit'))}
                        allowClear
                        optionFilterProp="children"
                      >
                        {bankAccountOptions &&
                          bankAccountOptions.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.description} - {item.bank}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.cost_center_id}
                      field="cost_center_id"
                      label={t('fields:cost_center_id')}
                    >
                      <Select
                        name="cost_center_id"
                        disabled={values.free === true || (permissions && !permissions.includes('@congress/edit'))}
                        allowClear
                        optionFilterProp="children"
                      >
                        {costCenterOptions &&
                          costCenterOptions.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    {/* <FormControl
                      cols={{ xs: 6 }}
                      error={errors.organizer_id}
                      field="organizer_id"
                      label={t('fields:organizer_id')}
                    >
                      <Select
                        name="organizer_id"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                        allowClear
                        optionFilterProp="children"
                      >
                        {organizersOptions &&
                          organizersOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl> */}
                    {/* TODO: NEW FIELD FOR ORGANIZATORS */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.organizers}
                      field="organizers"
                      label={t('fields:organizers')}
                    >
                      <Select
                        name="organizers"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                        allowClear
                        showArrow
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                      >
                        {organizersOptions &&
                          organizersOptions.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                  </Row>
                  <Row>
                    <Can permission="@congress/edit">
                      <Upload
                        disabled={file}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleUpload}
                      >
                        <Button style={{ marginLeft: '0.5rem' }} id="uploadButton" color="default">
                          Anexar arquivo
                          <FaUpload />
                        </Button>
                      </Upload>
                    </Can>
                  </Row>
                  <Row>
                    <Thumb
                      file={file}
                      fileURL={recordData.flyer_url}
                      onDelete={() => {
                        handleRemoveFile();
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
