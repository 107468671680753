import styled, { createGlobalStyle } from 'styled-components';
import { Upload } from 'antd';

export const Global = createGlobalStyle`

`;

export const Container = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: ${(props) => props.size} !important;
    height: ${(props) => props.size} !important;
    max-width: ${(props) => props.size} !important;
    max-height: ${(props) => props.size} !important;
  }
`;

export const SearchForm = styled.div`
  width: 100%;
  margin-left: 0.5rem;
  margin-right: auto;
`;
