import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Home from '~/pages/Home';

import Users from '~/pages/Users';
import Roles from '~/pages/Roles';

import Person from '~/pages/Person';
import PersonForm from '~/pages/Person/form';

import Provider from '~/pages/Provider';
import ProviderForm from '~/pages/Provider/form';

import CostCenter from '~/pages/CostCenter';

import Cost from '~/pages/Cost';

import BankAccount from '~/pages/BankAccount';

import Annuity from '~/pages/Annuity';

import Congress from '~/pages/Congress';

import SubArea from '~/pages/SubArea';

import Area from '~/pages/Area';

import Course from '~/pages/Course';

import PaymentLot from '~/pages/PaymentLot';

import Speech from '~/pages/Speech';

import Movement from '~/pages/Movement';

import Transaction from '~/pages/Transaction';

import Review from '~/pages/Review';

import Management from '~/pages/Management';

export default function OtherRoutes() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />

      <Route exact path="/usuarios" component={Users} />
      <Route exact path="/roles" component={Roles} />

      <Route exact path="/pessoas" component={Person} />
      <Route exact path="/pessoas/novo" component={PersonForm} />
      <Route exact path="/pessoas/:id/edicao" component={PersonForm} />

      <Route exact path="/clientes" component={Provider} />
      <Route exact path="/clientes/novo" component={ProviderForm} />
      <Route exact path="/clientes/:id/edicao" component={ProviderForm} />

      <Route exact path="/centros-de-custo" component={CostCenter} />

      <Route exact path="/custos" component={Cost} />

      <Route exact path="/anuidades" component={Annuity} />

      <Route exact path="/contas-bancarias" component={BankAccount} />

      <Route exact path="/congressos" component={Congress} />

      <Route exact path="/areas" component={Area} />

      <Route exact path="/subareas" component={SubArea} />

      <Route exact path="/cursos" component={Course} />

      <Route exact path="/lotes-de-pagamento" component={PaymentLot} />

      <Route exact path="/palestras" component={Speech} />

      <Route exact path="/movimentos" component={Movement} />

      <Route exact path="/transacoes" component={Transaction} />

      <Route exact path="/relatorios" component={Review} />

      <Route exact path="/gerencias" component={Management} />

    </BrowserRouter>
  );
}
