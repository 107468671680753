/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';

const SubscriptionsCharts = ({ subscriptions }) => {
  const [chartData, setChartData] = useState(null);

  // Função utilitária para construir informações de gráfico
  const buildChartInfo = (data, label) => ({
    label: `${label} (${data.qty ?? 0} - ${data.percentage ?? 0}%)`,
    data: data.qty ?? 0,
  });

  useEffect(() => {
    try {
      const { awaitingPayment, exempt, paid } = subscriptions;

      // Construindo dados do gráfico
      const chartInfo = {
        paid: buildChartInfo(paid, 'Pagos'),
        exempt: buildChartInfo(exempt, 'Isentos'),
        awaitingPayment: buildChartInfo(awaitingPayment, 'Pendentes')
      };

      const formattedData = {
        labels: Object.values(chartInfo).map(info => info.label),
        datasets: [{
          label: 'Inscrições',
          data: Object.values(chartInfo).map(info => info.data),
          backgroundColor: [
            'rgba(75, 192, 192, 0.7)',
            'rgba(255, 206, 86, 0.7)',
            'rgba(255, 99, 132, 0.7)'
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(255, 99, 132, 1)'
          ],
          borderWidth: 1
        }]
      };
      setChartData(formattedData);
    } catch (error) {
      console.error('Error processing the data', error);
    }
  }, [subscriptions]);

  return (
    <div>
      <p style={{ textAlign: 'center', marginBottom: 10,fontSize: 12, color: '#93c051', paddingTop: 15 }}>Inscrições</p>
      {chartData ? <Pie data={chartData} /> : <p>Loading...</p>}
      <p style={{ textAlign: 'center', marginBottom: 10,fontSize: 12, color: '#93c051', paddingTop: 15 }}>Total Inscrições: {subscriptions?.allSubscriptions ?? 'Loading...'}</p>
    </div>
  );
};

export default SubscriptionsCharts;