/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row, Statistic } from 'antd';
import SubscriptionChart from '~/components/Charts/SubscriptionsChart';
import ResumesChart from '~/components/Charts/ResumesChart';
import { Box, Title } from '~/pages/Home/styles';

const CongressList = ({ congresses }) => (
  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 },{ xs: 8, sm: 16, md: 24, lg: 24 }]} style={{ paddingTop: 20 }}>
    {congresses &&
      congresses.length > 0 &&
      congresses.map(
        (item) =>
          item.congressOrganizerId !== 0 && (
            <Col key={item.id} xs={24} sm={12} md={12} lg={12} xl={12}>
                <Box style={{ backgroundColor: '#dfffdf' }}>
                  <Title>{item.congress}</Title>
                  <p style={{ textAlign: 'center', marginBottom: 20, fontSize: 12, color: '#93c051' }}>
                    (Dados do congresso)
                  </p>
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <SubscriptionChart subscriptions={item.subscriptions} />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <ResumesChart resumes={item.resumes} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Statistic
                        title="Saldo Financeiro"
                        decimalSeparator=","
                        groupSeparator="."
                        value={item.balance}
                        precision={2}
                        prefix="R$"
                        style={{ marginTop: 40, paddingLeft: 40 }}
                      />
                    </Col>
                  </Row>
                </Box>
            </Col>
          )
      )}
  </Row>
);

export default CongressList;
