/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Switch, Input, Select } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import { FaCamera, FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';

import { decrypt, crypt } from '~/Utils/index';
import { ImagePreview, UploadStyle, Container } from './styles';

const initialValues = {
  name: '',
  email: '',
  uf: '',
  active: true,
};

export default function ProfileForm({ visible, onClose, userID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [changedFile, setChangedFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const { cities } =
    JSON.parse(decrypt(localStorage.getItem('@App:needs'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:needs')));

  const updateProfileLocalStorage = async () => {
    try {
      const stored =
        JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
        JSON.parse(decrypt(localStorage.getItem('@App:user')));

      const { data } = await api.get('me');
      const { name, avatar_url, avatar_id } = JSON.parse(data);

      const cryptedUser = crypt(
        JSON.stringify({
          ...stored,
          name,
          avatar_url,
          avatar_id,
        })
      );

      localStorage.setItem('@App:user', cryptedUser);
    } catch (error) {
      errorHandler(error);
      message.error(t('messages:errorOnProfileUpdate'));
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    delete values.isEdit;
    try {
      if (values.id) {
        await api.put('/me', values);
        if (changedFile === true) {
          if (imageUrl !== null) {
            const files = new FormData();
            files.append('file', file.originFileObj);
            await api.post(`users/${recordData.id}/avatar`, files);
          } else {
            await api.delete(`users/${recordData.id}/avatar`);
          }
        }
      }
      await updateProfileLocalStorage();
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const handleSelectState = async (value, values) => {
    if (value) {
      const data = statesList.filter((current) => current.sigla === value);
      setCitiesOptions(data[0].cidades);

      setRecordData({
        ...values,
        uf: value,
        city: null,
      });
    }
  };

  const fetchStateOptions = async (uf) => {
    const { estados } = await cities;
    setStatesList(estados);

    const states = await estados.map((value) => value.sigla);
    setStatesOptions(states);

    if (uf) {
      const citiesData = await estados.filter((value) => value.sigla === uf);
      setCitiesOptions(citiesData && citiesData[0].cidades);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      if (!userID) {
        setRecordData(initialValues);
      } else {
        const response = await api.get('/me');
        const data = JSON.parse(response.data);
        data.isEdit = true;
        setImageUrl(data.avatar_url);
        setRecordData(data);
        await fetchStateOptions(data.uf);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (avatar) => {
    const isJpgOrPng = avatar.type === 'image/jpeg' || avatar.type === 'image/jpg' || avatar.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = avatar.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ser menor que 20MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    setLoading(true);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.originFileObj) {
      getBase64(info.file.originFileObj, (image) => {
        setImageUrl(image);
        setFile(info.file);
      });
    }

    setLoading(false);
  };

  const uploadButton = (
    <>
      <FaCamera size={24} />
      <div className="ant-upload-text">{t('messages:add')}</div>
    </>
  );

  const handleUpload = async () => {
    setChangedFile(true);
  };

  const uploadConfig = {
    name: 'file',
    action: handleUpload,
    listType: 'picture-card',
    showUploadList: false,
    beforeUpload,
    onChange: handleChange,
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userSchema = Yup.object().shape({
    isEdit: Yup.boolean().nullable(),
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().when('isEdit', {
      is: (value) => value !== true,
      then: Yup.string().required(),
    }),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
    office: Yup.string().required(),
    city: Yup.string().required(),
    uf: Yup.string().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={userSchema}
    >
      {({ errors, isSubmitting, values, submitForm, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.name}` : t('screens:users.title')}
          onCancel={onClose}
          style={{ maxWidth: '1100px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@profile/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <FormControl cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }} style={{ display: 'none' }} field="isEdit">
                <Input name="isEdit" value={!!userID} style={{ display: 'none' }} />
              </FormControl>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <FormControl cols={{ xs: 24 }} required field="avatar" style={{ alignItems: 'center' }}>
                    <>
                      <UploadStyle />
                      {imageUrl ? (
                        <ImagePreview className="img-preview" size="100px" background={imageUrl}>
                          <div className="img-preview-wrapper">
                            <div
                              aria-hidden="true"
                              className="img-preview-delete"
                              onClick={() => {
                                setImageUrl(null);
                                setFile(null);
                                setChangedFile(true);
                              }}
                            >
                              <FaRegTrashAlt size={24} />
                              {t('messages:delete')}
                            </div>
                            <div className="img-preview-file" />
                          </div>
                        </ImagePreview>
                      ) : (
                        <Container {...uploadConfig} size="100px">
                          <Spin spinning={loading}>{imageUrl || uploadButton}</Spin>
                        </Container>
                      )}
                    </>
                  </FormControl>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <Row>
                    <FormControl
                      cols={{ xs: 11 }}
                      error={errors.name}
                      field="name"
                      label={t('screens:users.data.name')}
                      required
                    >
                      <Input name="name" disabled={permissions && !permissions.includes('@profile/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 11 }}
                      error={errors.email}
                      field="email"
                      label={t('screens:users.data.email')}
                      required
                    >
                      <Input
                        type="email"
                        name="email"
                        disabled={permissions && !permissions.includes('@profile/edit')}
                      />
                    </FormControl>
                    <FormControl cols={{ xs: 2 }} field="active" label={t('screens:users.data.active')}>
                      <Switch name="active" disabled={permissions && !permissions.includes('@profile/edit')} />
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.password}
                      field="password"
                      label={t('screens:users.data.password')}
                      required
                    >
                      <Input
                        name="password"
                        type="password"
                        disabled={permissions && !permissions.includes('@profile/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.password_confirmation}
                      field="password_confirmation"
                      label={t('screens:users.data.password_confirmation')}
                      required
                    >
                      <Input
                        type="password"
                        name="password_confirmation"
                        disabled={permissions && !permissions.includes('@profile/edit')}
                      />
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 8 }}
                      error={errors.office}
                      field="office"
                      label={t('screens:users.data.office')}
                      required
                    >
                      <Input name="office" disabled={permissions && !permissions.includes('@profile/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.uf}
                      field="uf"
                      label={t('screens:users.data.uf')}
                      required
                    >
                      <Select
                        name="uf"
                        disabled={permissions && !permissions.includes('@profile/edit')}
                        allowClear
                        autoComplete="dontshow"
                        showSearch
                        optionFilterProp="children"
                        onChange={(value) => handleSelectState(value, values)}
                      >
                        {statesOptions &&
                          statesOptions.map((item) => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 10 }}
                      error={errors.city}
                      field="city"
                      label={t('screens:users.data.city')}
                      required
                    >
                      <Select
                        name="city"
                        disabled={(permissions && !permissions.includes('@profile/edit')) || citiesOptions.length === 0}
                        allowClear
                        autoComplete="dontshow"
                        showSearch
                        onChange={(value) => {
                          setRecordData({
                            ...values,
                            city: value,
                          });
                        }}
                        optionFilterProp="children"
                      >
                        {citiesOptions &&
                          citiesOptions.map((item) => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
