import React from 'react';

import { useAuth } from '~/contexts/auth';

import SignRoutes from './SignRoutes';
import OtherRoutes from './OtherRoutes';

export default function Routes() {
  const context = useAuth();
  const { signed } = context;

  return signed ? <OtherRoutes /> : <SignRoutes />;
}
