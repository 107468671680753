/* eslint-disable */
import { decrypt } from '~/Utils/index';

export default function Can({ children, permission, role }) {
  const { permissions: permissionList, roles: roleList } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));
  const checkAuth = () => {
    let auth = false;
    if (roleList && !roleList.includes('@superAdmin')) {
      if (role) {
        auth = roleList && roleList.includes(role);
      } else if (permission) {
        auth = permissionList && permissionList.includes(permission);
      } else {
        auth = false;
      }
    } else {
      auth = true;
    }

    return auth;
  };
  return (checkAuth() && children) || null;
}


