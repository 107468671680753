/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Spin, Tag, Switch, message, Col, Popconfirm } from 'antd';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import { Table, TableActions } from '~/components/Table';
import { FaRegTrashAlt } from 'react-icons/fa';
import Can from '~/components/Can';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import Pagination from '../Pagination';

export default function SubscriptionTable({ visible, onClose, isCongress, relatedID, setUpdated }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [congressData, setCongressData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  // total number of paid registrations
  const [totalPaid, setTotalPaid] = useState(0);
  // total number of registrations
  const [maxSubscriptions, setMaxSubscriptions] = useState(0);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);

  const handleExport = async () => {
    setLoading(true);
    try {
      if (recordData.length > 0) {
        let exportUrl;
        if (isCongress) {
          exportUrl = `/subscriptions/export/congresses/${relatedID}`;
        } else {
          exportUrl = `/subscriptions/export/courses/${relatedID}`;
        }
        await api
          .get(exportUrl, {
            method: 'GET',
            responseType: 'blob',
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let type;
            if (isCongress) {
              type = 'congresso';
            } else {
              type = 'curso';
            }

            link.setAttribute('download', `inscricoes_${type}_${relatedID}_${new Date().toLocaleDateString()}.xlsx`);
            document.body.appendChild(link);
            link.click();
          });
      } else {
        message.warning(t('messages:noRecordFound'));
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCongressData = async () => {
    setLoading(true);
    try {
      if (isCongress) {
        // get congress data
        const { data } = await api.get(`/congresses/${relatedID}`);
        // convert data to json
        const congressDataJson = JSON.parse(data);
        setMaxSubscriptions(congressDataJson.max_subscriptions);
        setTotalPaid(congressDataJson.paid_subscriptions);
        setCongressData(congressDataJson);
      } else {
        // get course data
        const { data } = await api.get(`/courses/${relatedID}`);
        // convert data to json
        const congressDataJson = JSON.parse(data);
        console.log(congressDataJson);
        setMaxSubscriptions(congressDataJson.max_subscriptions);
        setTotalPaid(congressDataJson.paid_subscriptions);
        setTotalSubscriptions(congressDataJson.total_subscriptions);
        setCongressData(congressDataJson);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      if (isCongress) {
        const { data } = await api.get(`/subscriptions/congresses/${relatedID}`, {
          params: {
            page,
            perPage,
            search,
          },
        });
        setRecordData(data.data);
        setMeta(data.meta);
      } else {
        const { data } = await api.get(`/subscriptions/courses/${relatedID}`, {
          params: {
            page,
            perPage,
            search,
          },
        });
        setRecordData(data.data);
        setMeta(data.meta);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSetAllPresent = async () => {
    setLoading(true);
    try {
      const type = isCongress ? 'congress' : 'course';
      await api.put(`subscriptions/allPresents/${type}/${congressData.id}`);
      fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeBoolean = async record => {
    setLoading(true);
    try {
      const newData = recordData.map(data => {
        const editedData = data.id === record.id ? { ...record, edited: true } : { ...data };
        return { ...editedData };
      });
      setRecordData(newData);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = recordData.filter(item => item.edited);

      if (data.length === 0) {
        message.warning('Nenhuma alteração detectada.');
        setLoading(false);
        return;
      }

      // se der algum problema com performance, apenar enviar array de edited para o backend e no backend processar esses updates em fila.
      data.forEach(async subscription => {
        await api.put(`/subscriptions/forceUpdate/${subscription.id}`, { ...subscription });
      });

      message.success('Alterações salvas com sucesso.');
      setUpdated && setUpdated(true);
      onClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/subscriptions/deleteSubscriptionAndItems/${id}`);
      message.success(t('messages:successDeleteSubscription'));
      setUpdated && setUpdated(true);
      await fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const tableColumns = [
    {
      title: t('fields:person_id'),
      dataIndex: 'person_id',
      key: 'person_id',
      render: (record, text) => {
        const name = (text.person && text.person.name) || '';
        if (name.length > 35) {
          return name.substring(0, 35);
        }
        return name;
      },
    },
    {
      title: t('screens:subscriptions.data.badge_name'),
      dataIndex: 'badge_name',
      key: 'badge_name',
    },
    {
      title: t('screens:subscriptions.data.badge_institution'),
      dataIndex: 'badge_institution',
      key: 'badge_institution',
    },
    {
      title: t('screens:subscriptions.data.tax_exempt'),
      dataIndex: 'tax_exempt',
      key: 'tax_exempt',
      render: (record, text) => (
        <Switch
          name="active"
          size="small"
          style={{ marginTop: '5px' }}
          checked={text.tax_exempt}
          onChange={e => {
            handleChangeBoolean({ ...text, tax_exempt: e });
          }}
        />
      ),
    },
    {
      title: t('screens:subscriptions.data.translator'),
      dataIndex: 'translator',
      key: 'translator',
      render: (record, text) => (
        <Switch
          name="active"
          size="small"
          style={{ marginTop: '5px' }}
          checked={text.translator}
          onChange={e => {
            handleChangeBoolean({ ...text, translator: e });
          }}
        />
      ),
    },
    {
      title: t('screens:subscriptions.data.presence'),
      dataIndex: 'presence',
      key: 'presence',
      render: (record, text) => (
        <Switch
          name="active"
          size="small"
          style={{ marginTop: '5px' }}
          checked={text.presence}
          onChange={e => {
            handleChangeBoolean({ ...text, presence: e });
          }}
        />
      ),
    },
    {
      title: t('screens:subscriptions.data.paid_out'),
      dataIndex: 'paid_out',
      key: 'paid_out',
      render: (record, text) =>
        text.paid_out ? <Tag color="green">{t('messages:yes')}</Tag> : <Tag color="volcano">{t('messages:no')}</Tag>,
    },
    {
      title: t('screens:subscriptions.data.foreign'),
      dataIndex: 'foreign',
      key: 'foreign',
      render: (record, text) =>
        text.foreign ? <Tag color="green">{t('messages:yes')}</Tag> : <Tag color="volcano">{t('messages:no')}</Tag>,
    },
    {
      title: t('screens:subscriptions.data.payment_transaction_code'),
      dataIndex: 'payment_transaction_code',
      key: 'payment_transaction_code',
      render: (record, text) => (
        <>
          <p style={{ fontSize: '8px', textAlign: 'center' }}>{text.payment_status || ''}</p>
          <p style={{ fontSize: '8px', textAlign: 'center' }}>{text.payment_transaction_code || ''}</p>
        </>
      ),
    },
    {
      title: t('screens:congresses.data.actions'),
      key: 'actions',
      align: 'center',
      render: record => (
        <TableActions>
          <Can permission="@congress/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];

  useEffect(() => {
    if (visible) {
      fetchCongressData();
      fetchRecordData();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      fetchRecordData();
    }
  }, [visible, page, perPage, search]);

  return (
    <Modal
      // put the congress name here in the title
      title={
        isCongress ? (
          <>
            {t('screens:subscriptions.type.congress')}
            <br />
            {congressData.description}
          </>
        ) : (
          t('screens:subscriptions.type.course')
        )
      }
      onCancel={onClose}
      visible={visible}
      loading={loading}
      centered
      width="1250px"
      footer={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          <Button
            disabled={recordData && recordData.length === 0}
            loading={loading}
            onClick={handleExport}
            style={{ position: 'absolute' }}
          >
            {t('screens:subscriptions.export')}
          </Button>
          <ModalFooter loading={loading} onCancel={onClose} onOk={handleSave} />
        </>
      }
    >
      <Spin spinning={loading}>
        <Box>
          {/* <TableHeader style={{ backgroundColor: '#ef0f0f', width: '100%'  }}> */}
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{ textAlign: 'left' }}>
              {totalPaid >= 0 && (
                <p style={{ color: 'black', paddingTop: '5px' }}>
                  {t('screens:subscriptions.totalSubscriptions')}: {totalSubscriptions}
                  <br />
                  {t('screens:subscriptions.maxSubscriptions')}: {maxSubscriptions}
                  <br />
                  {t('screens:subscriptions.totalPaid')}: {totalPaid}
                </p>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ textAlign: 'right' }}>
              <Input.Search onSearch={value => setSearch(value)} className="search-field" />
            </Col>
          </Col>
          <Col>
            <Popconfirm
              title={t('messages:confirmAllPresents')}
              okText={t('messages:yes')}
              onConfirm={() => handleSetAllPresent()}
              cancelText={t('messages:no')}
            >
              <Button color="primary" style={{ marginBottom: '10px' }}>
                Todos presente
              </Button>
            </Popconfirm>
          </Col>

          {/* </TableHeader> */}
          <Table
            rowKey="id"
            loading={loading}
            dataSource={recordData}
            columns={tableColumns}
            pagination={false}
            bordered
          />
          <Pagination
            onChange={value => {
              setPage(value);
            }}
            meta={meta}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Box>
      </Spin>
    </Modal>
  );
}
