/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
// import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { decrypt, getSuccessMessageByModule } from '~/Utils/index';

import api from '~/services/api';

const initialValues = {
  theme: '',
  presenter: '',
  date_time: '',
  congress_id: '',
};

export default function SpeechForm({ visible, onClose, areaID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [areasOptions, setSubareasOptions] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchSubareas = async () => {
    try {
      const { data } = await api.get('/subareas');
      setSubareasOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    delete values.isEdit;
    try {
      if (values.id) {
        await api.put(`/areas/${values.id}`, values);
        message.success(getSuccessMessageByModule(t('screens:areas.titleSingular'), t('messages:successUpdatedFem')));
      } else {
        await api.post('/areas', values);
        message.success(getSuccessMessageByModule(t('screens:areas.titleSingular'), t('messages:successCreatedFem')));
      }
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchRecord = async () => {
    if (!areaID) {
      setRecordData(initialValues);
    } else {
      const { data } = await api.get(`/areas/${areaID}`);
      setRecordData({ ...data, sub_areas: data.sub_areas.map((item) => item.id) });
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchSubareas();
      await fetchRecord();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const AreaSchema = Yup.object().shape({
    name: Yup.string().required(),
    sub_areas: Yup.array().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={AreaSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.name}` : t('screens:areas.title')}
          onCancel={onClose}
          style={{ maxWidth: '950px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@area/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.name}
                      field="name"
                      label={t('screens:areas.data.name')}
                      required
                    >
                      <Input name="name" disabled={permissions && !permissions.includes('@area/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.sub_areas}
                      field="sub_areas"
                      label={t('screens:areas.data.sub_areas')}
                      required
                    >
                      <Select
                        name="sub_areas"
                        disabled={permissions && !permissions.includes('@area/edit')}
                        allowClear
                        showArrow
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                      >
                        {areasOptions &&
                          areasOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name.toUpperCase()}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
