/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
// import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { decrypt, getSuccessMessageByModule } from '~/Utils/index';

import api from '~/services/api';

const initialValues = {
  theme: '',
  presenter: '',
  date_time: '',
  congress_id: '',
};

export default function SpeechForm({ visible, onClose, speechID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [congressOptions, setCongressOptions] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchCongresses = async () => {
    try {
      const { data } = await api.get('/congresses');
      setCongressOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    delete values.isEdit;
    try {
      if (values.id) {
        await api.put(`/speeches/${values.id}`, values);
        message.success(
          getSuccessMessageByModule(t('screens:speeches.titleSingular'), t('messages:successUpdatedFem'))
        );
      } else {
        await api.post('/speeches', values);
        message.success(
          getSuccessMessageByModule(t('screens:speeches.titleSingular'), t('messages:successCreatedFem'))
        );
      }
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchRecord = async () => {
    if (!speechID) {
      setRecordData(initialValues);
    } else {
      const response = await api.get(`/speeches/${speechID}`);
      const data = JSON.parse(response.data);
      setRecordData(data);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchCongresses();
      await fetchRecord();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const SpeechSchema = Yup.object().shape({
    presenter: Yup.string().required(),
    theme: Yup.string().required(),
    date_time: Yup.date().required(),
    congress_id: Yup.number().integer().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={SpeechSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.theme}` : t('screens:speeches.title')}
          onCancel={onClose}
          style={{ maxWidth: '950px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@speech/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.congress_id}
                      field="congress_id"
                      label={t('fields:congress_id')}
                      required
                    >
                      <Select
                        name="congress_id"
                        disabled={permissions && !permissions.includes('@speech/edit')}
                        allowClear
                        optionFilterProp="children"
                      >
                        {congressOptions &&
                          congressOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.description.toUpperCase()} - {item.place}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      error={errors.date_time}
                      cols={{ xs: 12 }}
                      field="date_time"
                      required
                      label={t('fields:date_time')}
                    >
                      <DatePicker
                        format="DD/MM/YYYY HH:mm:ss"
                        showTime
                        name="date_time"
                        placeholder={t('messages:select')}
                        locale={locale}
                        disabled={permissions && !permissions.includes('@speech/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.theme}
                      field="theme"
                      label={t('screens:speeches.data.theme')}
                      required
                    >
                      <Input name="theme" disabled={permissions && !permissions.includes('@speech/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.presenter}
                      field="presenter"
                      label={t('screens:speeches.data.presenter')}
                      required
                    >
                      <Input name="presenter" disabled={permissions && !permissions.includes('@speech/edit')} />
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
