/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Input, Modal, message, Spin, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { formatCPF } from '@brazilian-utils/brazilian-utils';

import errorHandler from '~/Utils/errorHandler';

import { normalizeString, getColorByPersonStatus } from '~/Utils/index';

import api from '~/services/api';
import { ModalFooter } from '~/components/Modal';
import Box from '~/components/Box';
import { TableHeader, Table } from '~/components/Table';

export default function ChargeForm({ visible, onClose }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const searchInput = React.createRef();

  // const { permissions } =
  //   JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
  //   JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const handleSubmit = async () => {
    setLoading(true);
    if (selectedRowKeys.length > 0) {
      try {
        await api.post('/people/annuities/charges', {
          people: selectedRowKeys,
        });
        message.success(t('messages:success'));
        onClose();
      } catch (error) {
        errorHandler(error);
      }
    } else {
      message.warning(t('messages:selectBeforeTryingToCharge'));
    }
    setLoading(false);
  };

  const handleReset = async () => {
    setSearchTerm(null);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const response = await api.get('/people/annuities/charges');
      const { data } = response;
      setRecordData(data);
      handleReset();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setIsSearching(true);
      const results = recordData.filter(
        (item) =>
          item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          normalizeString(item.name).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.cpf.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          normalizeString(item.cpf).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      );
      setTableData(results);
    } else {
      setIsSearching(false);
      setTableData(recordData);
    }
  }, [recordData, searchTerm]);

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const tableColumns = [
    {
      title: t('screens:people.data.name'),
      dataIndex: 'name',
      key: 'name',
      render: (record) => {
        if (record.length > 35) {
          return record.substring(0, 35);
        }
        return record;
      },
    },
    {
      title: t('screens:people.data.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('screens:people.data.cpf'),
      dataIndex: 'cpf',
      key: 'cpf',
      render: (record, text) => formatCPF(text.cpf),
    },
    {
      title: t('screens:people.data.last_charge_at'),
      dataIndex: 'last_charge_at',
      key: 'last_charge_at',
      render: (record, text) => (
        <span style={{ fontSize: '10px' }}>
          {text.last_charge_at && new Date(text.last_charge_at).toLocaleString()}
        </span>
      ),
    },
    {
      title: `${t('screens:people.data.status')} x ${t('screens:people.data.validity')}`,
      key: 'status',
      render: (record, text) => (
        <Tag style={{ textTransform: 'uppercase' }} color={getColorByPersonStatus(text.status)}>
          {text.status}
          <br />
          <b style={{ fontSize: '10px' }}>{text.validity && new Date(text.validity).toLocaleDateString()}</b>
        </Tag>
      ),
    },
  ];

  return (
    <Modal
      title={t('screens:annuities.charges')}
      onCancel={onClose}
      style={{ maxWidth: '1050px' }}
      visible={visible}
      loading={loading}
      centered
      width="90%"
      footer={<ModalFooter loading={loading} onOk={handleSubmit} okText="Enviar" onCancel={onClose} />}
    >
      <Spin spinning={loading}>
        <Box>
          <TableHeader>
            <span style={{ width: '100%' }}>
              {t('screens:annuities.data.selected')}: <b>{selectedRowKeys.length}</b>
            </span>
            <Input.Search onSearch={(value) => setSearchTerm(value)} ref={searchInput} className="search-field" />
          </TableHeader>
          <Table
            // pageSize={1}
            rowKey="id"
            rowSelection={{
              selectedRowKeys,
              onChange: (keys) => {
                const keysNonChanged = keys;

                const data = [];
                keys.forEach((index) => {
                  data.push({ index, checked: true });
                });

                if ((selectedRows.length > 0 && data.length > 0) || selectedRowKeys.length > 0) {
                  let selected = [];

                  if (selectedRows.length > 0 && data.length > 0) {
                    selected = selectedRows
                      .concat(data)
                      .filter((value) => value.checked === true)
                      .map((value) => value.index);
                  }

                  // console.log('keys length', keysNonChanged.length, 'selectedRowKeys length', selectedRowKeys.length);
                  // user is not finding
                  if (
                    keysNonChanged.length > 0 &&
                    keysNonChanged.length < selectedRowKeys.length &&
                    isSearching === false
                  ) {
                    if (selectedRowKeys.length > 0) {
                      selected = selected.concat(selectedRowKeys);
                      // console.log('ok, concated', selectedRowKeys, 'all merged', selected);
                    }

                    selected = selected.filter((value) => keysNonChanged.includes(value));

                    // user is finding
                  } else if (
                    selectedRowKeys.length > 0 &&
                    keysNonChanged.length > 0 &&
                    isSearching &&
                    keysNonChanged.length <= selectedRowKeys.length
                  ) {
                    if (selectedRowKeys.length > 0) {
                      selected = selected.concat(selectedRowKeys);
                      // console.log('ok, concated on finding', selectedRowKeys, 'all merged', selected);
                    }

                    searchInput.current.state.value = null;
                    setSearchTerm(null);

                    selected = selected.filter(
                      (value) => selectedRowKeys.includes(value) || keysNonChanged.includes(value)
                    );
                  }

                  selected = selected.filter((v, i, a) => a.indexOf(v) === i);

                  // console.log('recovered selected ', selected);
                  setSelectedRowKeys(selected);
                } else {
                  setSelectedRows(data);
                  setSelectedRowKeys(keys);
                }
              },
            }}
            columns={tableColumns}
            dataSource={tableData}
          />
          <div
            style={{
              display: 'flex',
              float: 'none',
              justifyContent: 'flex-end',
            }}
          >
            <Tag color="green" style={{ maxWidth: '180px', margin: 'unset' }}>
              <small>Ativos expiram em até 3 meses</small>
            </Tag>
          </div>
        </Box>
      </Spin>
    </Modal>
  );
}
