/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaEye, FaScroll, FaNewspaper, FaCheck, FaSquare } from 'react-icons/fa';
import { FiClipboard } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input, message, Popconfirm, Tag, Statistic, Badge } from 'antd';
import { normalizeString, getSuccessMessageByModule } from '~/Utils';

import api from '~/services/api';
import SubscriptionTable from '~/components/Subscription';
import errorHandler from '~/Utils/errorHandler';

import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';

import CongressForm from './form';
import EvaluatorsForm from './EvaluatorsForm';
import AbstractsTable from './Abstract';
import { Global } from './styles';
import DefaultLayout from '../_layouts/full';

export default function Congress() {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showEvaluatorsForm, setShowEvaluatorsForm] = useState(false);
  const [showSubscriptionsForm, setShowSubscriptionsForm] = useState(false);
  const [showAbstractsForm, setShowAbstractsForm] = useState(false);
  const [updated, setUpdated] = useState(false);

  const { t } = useTranslation();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/congresses');
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    setShowForm(true);
  };

  const handleEdit = id => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`congresses/${id}`);
      message.success(getSuccessMessageByModule(t('screens:congresses.titleSingular'), t('messages:successDeleted')));
      fetchRecords();
    } catch (error) {
      if (error.response.status === 409) {
        message.warning('Não é possível remover um congresso com inscritos!');
      }
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEvaluator = id => {
    setSelectedRecord(id);
    setShowEvaluatorsForm(true);
  };

  const handleAbstract = id => {
    setSelectedRecord(id);
    setShowAbstractsForm(true);
  };

  const handleSubscriptions = id => {
    setSelectedRecord(id);
    setShowSubscriptionsForm(true);
  };

  useEffect(() => {
    if (!showForm && !showEvaluatorsForm && !showSubscriptionsForm && !showAbstractsForm) {
      setSelectedRecord(null);
      if (updated) {
        fetchRecords();
        setUpdated(false);
      }
    }
  }, [showForm, showEvaluatorsForm, showSubscriptionsForm, showAbstractsForm, updated]);

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      const results = recordData.filter(
        item =>
          item.description.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          normalizeString(item.description).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.place.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          (item.organizer && item.organizer.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.organizer &&
            normalizeString(item.organizer.name).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
      );
      setTableData(results);
    } else {
      setTableData(recordData);
    }
  }, [recordData, searchTerm]);

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '50px',
    },
    {
      title: t('screens:congresses.data.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:congresses.data.place'),
      dataIndex: 'place',
      key: 'place',
    },
    // {
    //   title: t('screens:congresses.data.free'),
    //   key: 'free',
    //   align: 'center',
    //   render: (text, record) => <Checkbox checked={record.free} disabled />,
    // },
    {
      title: t('screens:congresses.data.subscriptionsNumbers'),
      key: 'subscriptions',
      align: 'center',
      width: '300px',
      render: (text, record) => {
        const max = record.max_subscriptions;
        const paid = record.paid_subscriptions;
        const paidByAdmin = record.paid_subscriptions_by_admin;
        const total = record.total_subscriptions;
        const totalByAdmin = record.total_subscriptions_by_admin;
        const webDate = new Date(record.web_date);
        const finalDate = new Date(record.final_date);
        const available = webDate <= new Date() && finalDate >= new Date();
        // console.log([webDate, finalDate, new Date(), available]);
        const { free } = record;
        const percent = free ? (total / max) * 100 : (paid / max) * 100;
        const color = percent === 100 ? 'gray' : percent > 90 ? 'red' : percent > 50 ? 'orange' : 'green';
        if (free) {
          return (
            <Badge
              key={record.id}
              count={percent >= 100 ? <FaCheck color={available ? '#10e98e' : 'gray'} /> : 0}
              offset={[-50, 10]}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  backgroundColor: '#efefef',
                  width: 200,
                }}
              >
                <Tag
                  color={available ? '#10e98e' : '#81A89E'}
                  style={{ width: 80, whiteSpace: 'nowrap', marginLeft: 0, marginRight: 0 }}
                >
                  {t('screens:congresses.data.max')}: {max}
                  <br />
                  <span style={{ fontSize: '10px' }}>GRATUÍTA</span>
                </Tag>

                <Tag
                  onClick={() => handleSubscriptions(record.id)}
                  style={{ width: 120, whiteSpace: 'nowrap', marginLeft: 0, marginRight: 0, cursor: 'pointer' }}
                >
                  {/* {t('screens:congresses.data.total')}:  */}
                  {total}
                  <Statistic value={percent} valueStyle={{ color, fontSize: '10px' }} precision={1} suffix="%" />
                </Tag>
                <Tag
                  onClick={() => handleSubscriptions(record.id)}
                  style={{ width: 120, whiteSpace: 'nowrap', marginLeft: 0, marginRight: 0, cursor: 'pointer' }}
                >
                  {t('screens:congresses.data.by_admin')}
                  <br />
                  {totalByAdmin}
                </Tag>
              </div>
            </Badge>
          );
        }
        return (
          <Badge key={record.id} count={percent >= 100 ? <FaCheck color="green" /> : 0} offset={[-12, 10]}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 200 }}>
              <Tag
                color={available ? '#108ee9' : '#819EA8 '}
                style={{ width: 80, whiteSpace: 'nowrap', marginLeft: 0, marginRight: 0 }}
              >
                {t('screens:congresses.data.max')}: {max}
                <br />
                <span style={{ fontSize: '10px' }}>PAGO</span>
              </Tag>
              <Tag
                onClick={() => handleSubscriptions(record.id)}
                style={{ width: 120, whiteSpace: 'nowrap', marginLeft: 0, marginRight: 0, cursor: 'pointer' }}
              >
                <b>{paid}</b>
                <span style={{ fontSize: '10px' }}>(PAG)/{total}</span>
                <Statistic value={percent} valueStyle={{ color, fontSize: '10px' }} precision={1} suffix="%" />
              </Tag>
              <Tag
                onClick={() => handleSubscriptions(record.id)}
                style={{ width: 120, whiteSpace: 'nowrap', marginLeft: 0, marginRight: 0, cursor: 'pointer' }}
              >
                {t('screens:congresses.data.by_admin')}
                <br />
                <b>{paidByAdmin}</b>
                <span style={{ fontSize: '10px' }}>(PAG)/{totalByAdmin}</span>
              </Tag>
            </div>
          </Badge>
        );
      },
    },
    {
      title: t('fields:cost_center_id'),
      dataIndex: 'cost_center_id',
      key: 'cost_center_id',
      render: (record, text) => text.cost_center && text.cost_center.name,
    },
    {
      title: t('fields:bank_account_id'),
      dataIndex: 'bank_account_id',
      key: 'bank_account_id',
      render: (record, text) => text.bank_account && text.bank_account.description,
    },
    {
      title: t('fields:organizers'),
      key: 'organizers',
      render: (record, text) =>
        text.organizers && text.organizers.length > 0
          ? text.organizers.map(organizer => organizer?.name).join(', ')
          : '',
    },
    {
      title: t('screens:congresses.data.areas'),
      dataIndex: 'areas',
      key: 'areas',
      render: (text, record) =>
        record?.areas?.map(
          (area, i, data) => `${area && area.name.toUpperCase()} ${data.length - 1 !== i ? ', ' : ''} `
        ),
    },
    {
      title: t('screens:congresses.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@congress/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@congress/edit">
            <Can permission="@congress/view">
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@congress/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
          <Can permission="@congress/edit">
            <Button onClick={() => handleAbstract(record.id)} size="small" title="Resumos">
              <FaNewspaper />
            </Button>
          </Can>
          <Can permission="@congress/edit">
            <Button onClick={() => handleEvaluator(record.id)} size="small" title="Avaliadores">
              <FiClipboard />
            </Button>
          </Can>
          <Can permission="@congress/edit">
            <Button onClick={() => handleSubscriptions(record.id)} size="small" title="Inscritos">
              <FaScroll />
            </Button>
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:congresses.title')}>
        <Can permission="@congress/edit">
          <Button loading={loading} color="primary" onClick={handleNew}>
            <FaPlus />
            {t('screens:congresses.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '50%' }}>
          <FaSquare size={20} color="#10e98e" style={{ marginRight: '10px' }} /> Gratuíto/ATIVO
          <FaSquare size={20} color="#108ee9" style={{ marginRight: '10px', marginLeft: '20px' }} /> Pago/ATIVO
          <FaSquare size={20} color="#81a89e" style={{ marginRight: '10px', marginLeft: '20px' }} /> Gratuíto/INATIVO
          <FaSquare size={20} color="#819ea8" style={{ marginRight: '10px', marginLeft: '20px' }} /> Pago/INATIVO
        </div>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />

        <Global />

        <CongressForm
          visible={showForm}
          congressID={selectedRecord}
          onClose={() => setShowForm(false)}
          setUpdated={setUpdated}
        />
        <EvaluatorsForm
          visible={showEvaluatorsForm}
          congressID={selectedRecord}
          onClose={() => setShowEvaluatorsForm(false)}
        />
        <SubscriptionTable
          visible={showSubscriptionsForm}
          isCongress
          relatedID={selectedRecord}
          onClose={() => setShowSubscriptionsForm(false)}
          setUpdated={setUpdated}
        />
        <AbstractsTable
          visible={showAbstractsForm}
          congressID={selectedRecord}
          onClose={() => setShowAbstractsForm(false)}
        />
      </Box>
    </DefaultLayout>
  );
}
